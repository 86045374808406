import apiClient from '../../utils/api';// You may need to install and configure axios for making API requests.
import axios from 'axios';
import {
  POST_BLOG_COMMENT_REQUEST,
  POST_BLOG_COMMENT_SUCCESS,
  POST_BLOG_COMMENT_FAIL,
  ALL_BLOG_COMMENT_REQUEST,
  ALL_BLOG_COMMENT_SUCCESS,
  ALL_BLOG_COMMENT_FAIL,
  UPDATE_BLOG_COMMENT_REQUEST,
  UPDATE_BLOG_COMMENT_SUCCESS,
  UPDATE_BLOG_COMMENT_FAIL,
  DELETE_BLOG_COMMENT_REQUEST,
  DELETE_BLOG_COMMENT_SUCCESS,
  DELETE_BLOG_COMMENT_FAIL,
  CLEAR_ERRORS,
} from '../constants/blogComment';
import { baseUrl } from "../../utils/baseUrl";
const csrf = () => apiClient.get('/sanctum/csrf-cookie')

export const postBlogComment = (commentData, userId,id) => async (dispatch) => {
  try {
    await csrf();
    dispatch({ type: POST_BLOG_COMMENT_REQUEST });

    const body = {
      "user_id": userId,
      "blog_id": id,
      "comment":commentData
    }
    const response = await apiClient.post(`${ baseUrl}/comments`, body);

    dispatch({
      type: POST_BLOG_COMMENT_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: POST_BLOG_COMMENT_FAIL,
      payload: error?.response ? error?.response?.data?.message : error?.message,
    });
  }
};



export const getAllBlogComments = (blogId) => async (dispatch) => {
  try {
    dispatch({ type: ALL_BLOG_COMMENT_REQUEST });

    const response = await axios.get(`${baseUrl}/comment/${blogId}`);

    dispatch({
      type: ALL_BLOG_COMMENT_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: ALL_BLOG_COMMENT_FAIL,
      payload: error?.response ? error?.response?.data?.message : error?.message,
    });
  }
};


// Action to update a blog comment
export const updateBlogComment = (comment,commentId,userId,id) => async (dispatch) => {

  try {
    await csrf();
    dispatch({ type: UPDATE_BLOG_COMMENT_REQUEST });
    const body = {
      "user_id": userId,
      "blog_id": id,
      "comment": comment,
    }

 
    const response = await apiClient.put(`${baseUrl}/comments/${commentId}`, body);
    dispatch({
      type: UPDATE_BLOG_COMMENT_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BLOG_COMMENT_FAIL,
      payload: error?.response ? error?.response?.data?.message : error?.message,
    });
  }
};


export const deleteBlogComment = (commentId,userId) => async (dispatch) => {
  try {
    await csrf();
    dispatch({ type: DELETE_BLOG_COMMENT_REQUEST });

    const body = {
      "user_id": userId.toString(),
    }
    await apiClient.delete(`${baseUrl}/comments/${commentId}`, body);

    dispatch({ type: DELETE_BLOG_COMMENT_SUCCESS });
  } catch (error) {
    dispatch({
      type: DELETE_BLOG_COMMENT_FAIL,
      payload: error?.response ? error?.response?.data?.message : error?.message,
    });
  }
};



export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
