import React from 'react';

const ContactMap =()=> {
        return (
            <>
                <section className="contact-page-google-map">
                   

                    <iframe
                        title="Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3687.124687907007!2d91.96848427398133!3d22.461948237019328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30ad2fca34ae5549%3A0x35c88a37b3e90e97!2z4Kaa4Kaf4KeN4Kaf4KaX4KeN4Kaw4Ka-4KauIOCmquCnjeCmsOCmleCnjOCmtuCmsiDgppMg4Kaq4KeN4Kaw4Kav4KeB4KaV4KeN4Kak4Ka_IOCmrOCmv-CmtuCnjeCmrOCmrOCmv-CmpuCnjeCmr-CmvuCmsuCnnyAo4Kaa4KeB4Kef4KeH4KafKQ!5e0!3m2!1sbn!2sbd!4v1701281397122!5m2!1sbn!2sbd"
                        className="contact-page-google-map__one" allowFullScreen
                    ></iframe>

                </section>
            </>
        )
    
}
export default ContactMap