import {
  ALL_EVENT_REQUEST,
  ALL_EVENT_SUCCESS,
  ALL_EVENT_FAIL,
  SINGLE_EVENT_REQUEST,
  SINGLE_EVENT_SUCCESS,
  SINGLE_EVENT_FAIL,
  CLEAR_ERRORS,
  PAST_EVENT_REQUEST,
  PAST_EVENT_SUCCESS,
  PAST_EVENT_FAIL,
  UPCOMING_EVENT_REQUEST,
  UPCOMING_EVENT_SUCCESS,
  UPCOMING_EVENT_FAIL,
} from '../constants/eventCostant';

const initialState = {
  events: [],
  event: {},
  loading: false,
  error: null,
  pastEvents: [],
  upcomingEvents: [],
};

export const eventReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ALL_EVENT_REQUEST:
    case SINGLE_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action?.payload,
      };

    case SINGLE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        event: action?.payload,
      };

    case ALL_EVENT_FAIL:
    case SINGLE_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case PAST_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PAST_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        pastEvents: action?.payload,
      };

    case PAST_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case UPCOMING_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPCOMING_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        upcomingEvents: action?.payload,
      };

    case UPCOMING_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
