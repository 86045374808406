import {
  ALL_GALLERY_REQUEST,
  ALL_GALLERY_SUCCESS,
  ALL_GALLERY_FAIL,
  SINGLE_GALLERY_REQUEST,
  SINGLE_GALLERY_SUCCESS,
  SINGLE_GALLERY_FAIL,
  CLEAR_GALLERY_ERRORS
} from "../constants/galleryConstants";

const initialState = {
  galleries: [],
  gallery: null,
  loading: false,
  error: null
};

const galleryReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ALL_GALLERY_REQUEST:
    case SINGLE_GALLERY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ALL_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        galleries: action?.payload,
        error: null
      };

    case SINGLE_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        gallery: action?.payload,
        error: null
      };

    case ALL_GALLERY_FAIL:
    case SINGLE_GALLERY_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload
      };

    case CLEAR_GALLERY_ERRORS:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};

export default galleryReducer;
