import React from 'react'
import NoticeViewComponent from '../components/notice/NoticeViewComponent'

const NoticeView = () => {
  return (
    <div>
      <NoticeViewComponent/>
    </div>
  )
}

export default NoticeView