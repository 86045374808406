import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getGalleries,
} from "../../redux/actions/galleryAction";

const EventThree = () => {
  const alert = useAlert();
  const { galleries, error } = useSelector((state) => state.galleries);
  const dispatch = useDispatch();
  const { data: galleryData } = galleries;

  useEffect(() => {
    dispatch(getGalleries(1));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [ dispatch]);


  return (
    <>
      <section className="project-one">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Gallery</h2>
          </div>
          {galleryData ?
            <div>
              <div className="row ">
                {galleryData?.slice(0, 6)?.map((gallery, index) => (
                  <div
                    className="col-xl-4 col-lg-4 col-md-6 "
                    data-wow-delay="100ms"
                    key={index}
                  >
                    <div className="project-one__single">
                      <div className="project-one__img">
                        {gallery?.images ?
                          <img
                            src={gallery?.images[0]}
                            alt={gallery?.title}
                          />
                          :
                          <p>Event image not Found !!</p>
                        }

                        <div className="project-one__content">
                          <p className="project-one__sub-title">
                            Our Best Event Gallery
                          </p>
                          <h3 className="project-one__title">
                            <Link to={`/galleries/${gallery?.id}`}>
                              {gallery?.title}
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>
              <div className="pagination-div">

              </div>
            </div>
            : <p>Gallery Data is Empty!!</p>}
        </div>
      </section>
    </>
  );
}

export default EventThree;
