import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import SetPasswordComponent from "../components/user/SetPassword";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";

const SetPassword = () => {
  const token = useParams().token;
  return (
    <>
      <HeaderOne />
      <Breadcrumb heading="Set password" currentPage="Set password" />
      <SetPasswordComponent token={token} />
    </>
  );
};

export default SetPassword;
