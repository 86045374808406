import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterTwo from '../common/footer/FooterTwo';
import GalleryDetailsComponent from '../components/gallery/GalleryDetailsComponent';

const GalleryDetails = () => {
  return (
    <>
      <HeaderOne />
      <Breadcrumb
        heading="Gallery"
        currentPage={`Gallery / Event-Gallery`}
      />
      <GalleryDetailsComponent />
      <FooterTwo />
    </>
  )
}

export default GalleryDetails