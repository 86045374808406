export const POST_BLOG_COMMENT_REQUEST = 'POST_BLOG_COMMENT_REQUEST';
export const POST_BLOG_COMMENT_SUCCESS = 'POST_BLOG_COMMENT_SUCCESS';
export const POST_BLOG_COMMENT_FAIL = 'POST_BLOG_COMMENT_FAIL';

export const ALL_BLOG_COMMENT_REQUEST = 'ALL_BLOG_COMMENT_REQUEST'
export const ALL_BLOG_COMMENT_SUCCESS = 'ALL_BLOG_COMMENT_SUCCESS'
export const ALL_BLOG_COMMENT_FAIL = 'ALL_BLOG_COMMENT_FAIL'

export const UPDATE_BLOG_COMMENT_REQUEST = 'UPDATE_BLOG_COMMENT_REQUEST';
export const UPDATE_BLOG_COMMENT_SUCCESS = 'UPDATE_BLOG_COMMENT_SUCCESS';
export const UPDATE_BLOG_COMMENT_FAIL = 'UPDATE_BLOG_COMMENT_FAIL';


export const DELETE_BLOG_COMMENT_REQUEST = 'DELETE_BLOG_COMMENT_REQUEST';
export const DELETE_BLOG_COMMENT_SUCCESS = 'DELETE_BLOG_COMMENT_SUCCESS';
export const DELETE_BLOG_COMMENT_FAIL = 'DELETE_BLOG_COMMENT_FAIL';


export const CLEAR_ERRORS = 'CLEAR_ERRORS'