import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import FooterTwo from "../common/footer/FooterTwo";
import RuleBookComponent from "../components/rulebook/RuleBookComponent";

const RuleBook = () => {
  return (
    <div><HeaderOne />
      <Breadcrumb heading="Rule Book" currentPage="Rule Book" />
      <RuleBookComponent />

      <FooterTwo />
    </div>
  )
}

export default RuleBook


