import axios from 'axios';
import {

  CLEAR_ERRORS,
  ALL_JOB_POST_REQUEST,
  ALL_JOB_POST_SUCCESS,
  ALL_JOB_POST_FAIL,
  SINGLE_JOB_POST_REQUEST,
  SINGLE_JOB_POST_SUCCESS,
  SINGLE_JOB_POST_FAIL,
} from '../constants/jobPostConstant';
import { baseUrl } from '../../utils/baseUrl';





export const getAllJobPosts = (pageNumber) => async (dispatch) => {
  try {
    dispatch({ type: ALL_JOB_POST_REQUEST });

    const { data } = await axios.get(`${baseUrl}/job-posts?page=${pageNumber}`);
    dispatch({ type: ALL_JOB_POST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ALL_JOB_POST_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

export const getSingleJobPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_JOB_POST_REQUEST });

    const { data } = await axios.get(`${baseUrl}/job-posts/${id}`);

    dispatch({ type: SINGLE_JOB_POST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SINGLE_JOB_POST_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
