import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import ForgotPasswordComponent from "../components/user/ForgotPassword";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";


const ForgotPassword = () => {
  return (
    <>
      <HeaderOne />
      <Breadcrumb heading="Forgot password" currentPage="Forgot password" />
      <ForgotPasswordComponent />
    </>
  );
};

export default ForgotPassword;
