import {
  ALL_NEWS_REQUEST,
  ALL_NEWS_SUCCESS,
  ALL_NEWS_FAIL,
  SINGLE_NEWS_REQUEST,
  SINGLE_NEWS_SUCCESS,
  SINGLE_NEWS_FAIL,
  LATEST_NEWS_REQUEST,
  LATEST_NEWS_SUCCESS,
  LATEST_NEWS_FAIL,
  CLEAR_ERRORS,
} from '../constants/newsConstants';

const initialState = {
  allNews: [],
  singleNews: null,
  latestNews: [],
  loading: false,
  error: null,
};

export const newsReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ALL_NEWS_REQUEST:
    case SINGLE_NEWS_REQUEST:
    case LATEST_NEWS_REQUEST:
      return { ...state, loading: true, error: null };

    case ALL_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allNews: action?.payload,
      };

    case SINGLE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleNews: action?.payload,
      };

    case LATEST_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        latestNews: action?.payload,
      };

    case ALL_NEWS_FAIL:
    case SINGLE_NEWS_FAIL:
    case LATEST_NEWS_FAIL:
      return { ...state, loading: false, error: action?.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null, message: null };

    default:
      return state;
  }
};

export default newsReducer;
