import React, { useEffect, useState } from "react";
import "./Message.css";
import { Link } from "react-router-dom";
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors } from "../../redux/actions/userActions";
import { destroy, getChats,getMessages ,store} from "../../redux/actions/chatAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { imageUrl } from "../../utils/baseUrl";
import { formatDate, formatTime } from "../../utils/timeDateFormate";
import Loader from "../../common/Loader/Loader";


const MessageComponent = () => {
  const alert = useAlert();
  const { chats, error, singleChat, loading } = useSelector(state => state.chats);
  const { user } = useSelector(state => state.auth);


  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedName, setSelectedName] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [messageInput, setMessageInput] = useState('');

  const dispatch = useDispatch();


  
  const handleChatClick = (chatId,name,avater) => {
    setSelectedChat(chatId);
    dispatch(getMessages(chatId));
    setSelectedName(name); 
    setSelectedImage(avater);
  };

  const handleSendMessage = () => {
    if (!messageInput?.trim()) {
      alert.error('Message cannot be empty');
      return;
    }

    dispatch(store(selectedChat, messageInput));
    dispatch(getMessages(selectedChat));
    dispatch(getChats());
    // Assuming sendMessage takes chatId and message content
    setMessageInput(''); // Clear input after sending message
  };
  const handleDeleteMessage = (id) => {
    dispatch(destroy(id));
    dispatch(getMessages(selectedChat));
  };

  useEffect(() => {
    dispatch(getChats());
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

  }, [ dispatch])
  return (
    <div>
      {
        loading ? <Loader /> : (
          <div className="content">
            <div className="container p-0">
              <hr />
              <h1 className="h3 mb-3 text-center">Messages</h1>
              <hr />

              <div className="card">
                <div className="row g-0">
                  {chats?.length > 0 ? (
                    <div className="col-12 col-lg-5 col-xl-3 border-right chat-sitebar">
                      {chats?.map((chat, index) => (
                        <Link
                          key={index}
                          to="#"
                          className="list-group-item list-group-item-action border-0"
                          onClick={() => handleChatClick(chat?.id, chat?.name, chat?.avatar)}
                        >
                          <div className="d-flex align-items-start ">
                            <img
                              src={chat?.avatar ? imageUrl + '/' + chat?.avatar : "https://bootdey.com/img/Content/avatar/avatar5.png"}
                              className="rounded-circle mr-2 m-1"
                              alt={chat?.name}
                              width="40"
                              height="40"
                            />
                            <div className="flex-grow-1  m-1">
                              {chat?.name}
                              <div className="small">{chat?.last_message}</div>
                            </div>
                          </div>
                        </Link>
                      ))}
                      <hr className="d-block d-lg-none mt-1 mb-0" />
                    </div>
                  ) : (
                    <div>No Messages Found</div>
                  )}
            
          
            
                  <div className="col-12 col-lg-7 col-xl-9">
                    {selectedImage &&
                      <div className="py-2 px-4 border-bottom d-none d-lg-block">
                        <div className="d-flex align-items-center py-1">
                          <div className="position-relative">
                            <img src={imageUrl + '/' + selectedImage} className="rounded-circle mr-1" alt={selectedName} width="40" height="40" />
                          </div>
                          <div className="flex-grow-1 p-3">
                            <strong>{selectedName}</strong>
  
                          </div>
                        </div>
                      </div>

                    }
             

                    {singleChat && singleChat?.length > 0 && selectedName?.length > 0 ? (
                      <div className="chat-messages p-4">
                        {singleChat?.map((message, index) => (
                    
                          <div key={index} className={`chat-message-${message?.role === 'sender' ? 'right' : 'left'} pb-4`}>
                     
                     
                            {message?.timestamp &&
                              <div className="m-3">
                                <img
                                  src={message?.role === 'sender' ? `${imageUrl + '/' + user?.avatar}` : `${imageUrl + '/' + selectedImage}`}
                                  className="rounded-circle mr-1"
                                  alt={message?.role}
                                  width="40"
                                  height="40"
                                />
                                <div className="text-muted small text-nowrap">
                                  <b>
                                    <div>{formatDate(message?.timestamp)}</div>
                                    <div>{formatTime(message?.timestamp)}</div>

                                  </b>
                         
                                </div>
                              </div>
                            }
                      
                            {message?.message &&
                              <div className={`flex-shrink-1 bg-light rounded py-2 m-0 px-3  ${message?.role === 'sender' ? 'mr-3' : 'ml-3'}`}>
                                <b>
                                  <div className="font-weight-bold mb-1">{message?.role === 'sender' ? 'You' : selectedName}</div>
                                </b>
                                <div>
                              
                    
                                  {message?.role === 'sender' && (
                                    <FontAwesomeIcon icon={faTrash} className="m-1 cursor-pointer" onClick={() => handleDeleteMessage(message?.id)} />
                                  )}
                            
                                  <span className="m-1">{message?.message}</span>
                                </div>
                        
                              </div>
                            }
                        
                    
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center mt-5">No messages found for the selected chat.</div>
                    )}
                    {selectedName?.length > 0 &&
                      <div className="flex-grow-0 py-3 px-4 border-top">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type your message"
                            value={messageInput}
                            onChange={(e) => setMessageInput(e.target.value)}
                          />
                 
                          <button className="thm-btn comment-form__btn full_button profile-button m-0" onClick={handleSendMessage}>Send</button>
                        </div>
                      </div>
                    }
                  </div>
            
                </div>
              </div>
            </div>
          </div>
        )
      }
      </div>
  );
};

export default MessageComponent;
