import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getBlogs, clearErrors } from '../../redux/actions/blogAction';
import Loader from '../../common/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateTimeWithAMPM } from '../../utils/timeDateFormate'
import { useAlert } from 'react-alert';
import Pagination from "react-js-pagination";
import { removeHTMLTags } from '../../utils/removeHTMLTags';

const BlogsCardTwo = () => {
  const alert = useAlert();
  const { blogs, error, loading } = useSelector(state => state.blogs);
  const dispatch = useDispatch();
  const { data: blogData, meta } = blogs;
  const [activePage, setActivePage] = useState(meta?.current_page || 1);


  useEffect(() => {
    dispatch(getBlogs(activePage));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

  }, [activePage, dispatch])


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <div>
      {
        loading ? <Loader /> : (
          <section className="recent-event">
            {blogData ?
              <div>
                <div className="container">

                  <div className="row">
                    {
                      blogData?.map((blog,index) => (
                        <div className="recent-event__single  col-lg-6 wow fadeInUp " data-wow-delay="100ms" key={index}>
                          <div className="recent-event__img ">
                            <img src={blog?.image} alt="" />
                            <div className="recent-event__btn-box">
                              <Link to={`/blogs/${blog?.id}`} className="thm-btn recent-event__btn">Read More</Link>
                            </div>
                          </div>
                          <div className="recent-event__content">
                            <ul className="list-unstyled recent-event__meta">
                              <li>
                                <p> <span className="icon-calendar"></span> {formatDateTimeWithAMPM(blog?.date)?.slice(0, 16)}</p>
                              </li>
                              <li>
                                <p> <span className="icon-back-in-time"></span> {formatDateTimeWithAMPM(blog?.date)?.slice(19)} </p>
                              </li>
                            </ul>
                            <h3 className="recent-event__title"><Link to={`/blogs/${blog?.id}`}>{blog?.title?.slice(0, 35)} {blog?.title?.length >= 36 ? "..." : ""}</Link></h3>
                            <p className="recent-event__text justyfy-content" >{removeHTMLTags(blog?.description)?.slice(0, 90)} ...</p>

                          </div>
                        </div>
                      ))}
                    <div>
                      {blogData?.length === 0 && (
                        <p>Upcomming Event List is empty</p>
                      )}
                    </div>

                  </div>
                </div>
                <div className="pagination-div">

                  {meta?.total > meta?.per_page && (
                    <div className="pagination-div">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={meta?.per_page}
                        totalItemsCount={meta?.total || 0}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        activeLinkClass="active-pagination-button"
                      />
                    </div>
                  )}
                </div>
              </div>
              :
              <p className='px-5'>Blog page is Empty!!</p>}


          </section>
        )}
    </div>
  );
};

export default BlogsCardTwo;

