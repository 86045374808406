import React, { useEffect } from "react";
import { clearErrors } from "../../redux/actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import Loader from "../../common/Loader/Loader";

const HistoryComonent = () => {

  const dispatch = useDispatch();
  const alert = useAlert();
  const { settings, error, loading } = useSelector(state => state.settings);
  const history = settings?.data[7]?.value;

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [ dispatch]);


  return (
    <div>
      {
        loading ? <Loader /> : (

          <div className="container py-5">
            {history ?
              <p className="blog-details__text-1 pb-5" dangerouslySetInnerHTML={{ __html: history }} ></p>

              :
              <p>History page is empty !!</p>
            }

          </div>
        )}
    </div>

  );
};

export default HistoryComonent;
