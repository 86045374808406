import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpcomingEvents } from "../../redux/actions/eventAction";
import { convertDateFormat, timeCounterDateTimeFormate } from "../../utils/timeDateFormate";
import { Link } from "react-router-dom";
import { removeHTMLTags } from "../../utils/removeHTMLTags";

const UpcomingEvents = () => {
  let publicUrl = process.env.PUBLIC_URL + '/';
  const {  upcomingEvents } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const { data: upcomingEvenData } = upcomingEvents;
  const [formattedDateTime, setFormattedDateTime] = useState("Jan 15, 2025 12:00:00 PDT");

  useEffect(() => {
    dispatch(getUpcomingEvents());
  }, [dispatch]);

  useEffect(() => {
    if (upcomingEvenData?.length > 0) {
      const eventData = upcomingEvenData[0];
      if (eventData?.date?.start && eventData?.time?.start) {
        const newFormattedDateTime = timeCounterDateTimeFormate(eventData?.date, eventData?.time);
        setFormattedDateTime(newFormattedDateTime);
      } else {
        console.error("Invalid date or time structure:", eventData?.date, eventData?.time);
      }
    }
  }, [upcomingEvenData]);

  useEffect(() => {
    const makeTimer = () => {
      const endTime = new Date(formattedDateTime);
      const endTimeInSeconds = Math.floor(endTime?.getTime() / 1000);
      const now = Math.floor(Date.now() / 1000);
      let timeLeft = endTimeInSeconds - now;

      const days = Math.floor(timeLeft / 86400);
      timeLeft -= days * 86400;
      const hours = Math.floor(timeLeft / 3600);
      timeLeft -= hours * 3600;
      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;

      const formatNumber = (num) => (num < 10 ? `0${num}` : num);

      document.getElementById("days").innerHTML = `${days} <h6>Days</h6>`;
      document.getElementById("hours").innerHTML = `${formatNumber(hours)}<h6> Hours </h6>`;
      document.getElementById("minutes").innerHTML = `${formatNumber(minutes)} <h6> Minutes </h6>`;
      document.getElementById("seconds").innerHTML = `${formatNumber(seconds)}<h6> Seconds </h6>`;
    };

    const timerInterval = setInterval(makeTimer, 1000);

    return () => clearInterval(timerInterval);
  }, [formattedDateTime]);

  return (
    <>
      {/* <!-- Start Events Area --> */}
      <section className="events-area bg-color ptb-100">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="events-content mr-15">

                <h2 className="text-center"> Upcoming events</h2>

                <ul className="events-list">

                  <ul className="events-list">
                    {upcomingEvenData?.slice(0, 3)?.map((event, index) => (
                      <li key={index} className="events-item">
                        <div className="d-flex">
                          <div className="events-date">
                            <span className="mb-2">
                              {convertDateFormat(event?.date?.start)?.slice(
                                0,
                                6,
                              )}
                            </span>
                            <span>
                              {convertDateFormat(event?.date?.start)?.slice(7)}
                            </span>
                          </div>

                          <div>
                            <h3>
                              <Link to={`/events/${event?.id}`}>
                                {event?.title?.slice(0, 40)}
                                {event?.title?.length > 40 ? ".." : ""}
                              </Link>
                            </h3>
                            <p>
                              {removeHTMLTags(event?.description)?.slice(
                                0,
                                50,
                              )}
                              ...
                            </p>
                            <Link
                              to={`/events/${event?.id}`}
                              className="read-more"
                            >
                              Find out more
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{ marginLeft: "0.4rem" }}
                              />
                            </Link>
                          </div>
                          <div className="event_image">
                            <img src={event?.image} className="" alt="" />
                          </div>
                        </div>
                        <hr />
                      </li>
                    ))}
                    {upcomingEvenData?.length === 0 && (
                      <p>There are no upcoming events.</p>
                    )}
                  </ul>

                </ul>
              </div>
            </div>


            <div className="col-lg-5">
              <div className="events-timer ml-15">
                <div className="event-img">
                  {upcomingEvenData && upcomingEvenData?.length > 0 ? (
                    <img src={upcomingEvenData[0]?.image} alt="even-1" />
                  ) : (
                    <img
                      src={publicUrl + "assets/images/CUET_view/Campus_entry.jpg"}
                      alt="even-2"
                    />
                  )}

                </div>

                <span>Next Event</span>

                <div id="timer">
                  <div id="days"></div>
                  <div id="hours"></div>
                  <div id="minutes"></div>
                  <div id="seconds"></div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Events Area --> */}
    </>
  );
};

export default UpcomingEvents;
