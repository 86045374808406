import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, getSingleEvent } from '../../redux/actions/eventAction';
import Loader from '../../common/Loader/Loader';
import { formatDateTimeWithAMPM } from '../../utils/timeDateFormate';
import { imageUrl } from '../../utils/baseUrl';

const SingleEventDetails = () => {
  const { id } = useParams();
  const { error, loading, event } = useSelector(state => state.events);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleEvent(id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, id]);

  if (loading) {
    return <Loader />;
  }

  if (!event) {
    return <div>No event found.</div>;
  }
  let publicUrl = process.env.PUBLIC_URL + '/';


  return (
    <div>
      {
        loading ? <Loader /> : (
          <section className="blog-details">

            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12">

                  <div className="blog-details__left">

                    <div className="blog-details__img">
                      <img  className="even-image" src={event?.image} alt={event?.title} />
                      <div className="blog-details__date">
                        <p> <i className="far fa-calendar"></i>
                         {event?.start_time}
                         </p>
                       
                      </div>
                    </div>
                    <div className="blog-details__content">

                      <h3 className="blog-details__title">{event?.title}</h3>

                      <h3 className='pb-2'>Start time </h3>
                      <p> <i className="far fa-calendar pb-5"></i> { event?.start_time}</p>

                      <h3 className='pb-2'>End time </h3>
                      <p> <i className="far fa-calendar pb-5"></i> {event?.end_time}</p>

                      <h3 className='pb-2'>Vanue </h3>
                      <p className="event-details__venue pb-5"><i className="fas fa-map-marker-alt"></i> {event?.venue} </p>

                      <h3 className='pb-2'>Event Description </h3>
                      <p className="blog-details__text-1 pb-5" dangerouslySetInnerHTML={{ __html: event?.description }}></p>
                    </div>

                    <div className="author-one p-4">
                      <div className="author-one__image">
                        <Link to={`/members/${event?.user_id}`}>
                          {event?.user_avater ?
                            <img src={ imageUrl+'/'+event?.user_avater} alt={event?.user_name} />
                            :

                            <img src={publicUrl + "assets/images/deafult_avater/graduate-avater.jpg"} alt="Member" />

                          }
                        </Link>

                      </div>

                      <div className="author-one__content">
                        <p className="text-base-color" >Created by</p>
                        <Link to={`/members/${event?.user_id}`}>
                          <h3>{event?.user_name}</h3>

                        </Link>

                      </div>
                    </div>



                  </div>

                </div>

              </div>
            </div>
          </section>
        )}
    </div>
  );
};

export default SingleEventDetails;
