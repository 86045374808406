import {
  ALL_CHAT_REQUEST,
  ALL_CHAT_SUCCESS,
  ALL_CHAT_FAIL,
  SINGLE_CHAT_REQUEST,
  SINGLE_CHAT_SUCCESS,
    SINGLE_CHAT_FAIL,
    MESSAGE_REQUEST,
    MESSAGE_SUCCESS,
  MESSAGE_FAIL,
  DELETE_MESSAGE_COMMENT_SUCCESS,
  DELETE_MESSAGE_COMMENT_REQUEST,
  DELETE_MESSAGE_COMMENT_FAIL
} from "../constants/chatConstant";
import { baseUrl } from "../../utils/baseUrl";
import apiClient from "../../utils/api";

const csrf = () => apiClient.get('/sanctum/csrf-cookie')
export const getChats = () => async (dispatch) => {
    try {
        await csrf();
        dispatch({ type: ALL_CHAT_REQUEST });
    
        const data = await apiClient.get(`${baseUrl}/chats`);
        dispatch({
        type: ALL_CHAT_SUCCESS,
        payload: data?.data
        });
    
    } catch (error) {
        dispatch({
        type: ALL_CHAT_FAIL,
        payload: error?.response?.data?.message
        });
    }
    };


export const getMessages = (id) => async (dispatch) => {
    try {
        await csrf();
        dispatch({ type: SINGLE_CHAT_REQUEST });
    
        const data = await apiClient.get(`${baseUrl}/messages/${id}`);
        dispatch({
        type: SINGLE_CHAT_SUCCESS,
        payload: data?.data
        });
    
    } catch (error) {
        dispatch({
        type: SINGLE_CHAT_FAIL,
        payload: error?.response?.data?.message
        });
    }
}
    
export const store = (recieverId, message)=>async(dispatch) => {
    try {
        await csrf();
        dispatch({ type: MESSAGE_REQUEST });
        const data = apiClient.post(`${baseUrl}/send-message`, {
            receiver_id: recieverId,
            message: message
        })
        dispatch({
            type: MESSAGE_SUCCESS,
            payload: data?.data
        });
    }
    catch (error)
    {
        dispatch({
            type: MESSAGE_FAIL,
            payload: error?.response?.data?.message
        });
    }
    
}

export const destroy = (id) => async (dispatch) => {
    try {
        await csrf();
        dispatch({ type: DELETE_MESSAGE_COMMENT_REQUEST });
        const { data } = await apiClient.delete(`${baseUrl}/chat/${id}`);
        dispatch({
            type: DELETE_MESSAGE_COMMENT_SUCCESS,
            payload: data?.message
        });
        
    } catch (error) {
        dispatch({
            type: DELETE_MESSAGE_COMMENT_FAIL,
            payload: error?.response?.data?.data?.message
        });
    }
}