import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'

import { authReducer, forgotPasswordReducer } from './redux/reducers/userReducers'

import { batchReducer } from './redux/reducers/batchReducers';
import { departmentsReducer } from './redux/reducers/departmentReducers';
import { jobFieldReducer } from './redux/reducers/jobFieldReducers';
import { jobOrgReducer } from './redux/reducers/jobOrgReducers';
import { blogReducer } from './redux/reducers/blogReducer';
import { blogCommentReducer } from './redux/reducers/blogCommentReducer';
import { eventReducer } from './redux/reducers/eventRedcer';
import { jobPostReducer } from './redux/reducers/jobPostReducer';
import { committeeReducer } from './redux/reducers/committeeReducer';
import { settingsReducer } from './redux/reducers/settingsReducer';
import membersReducer from './redux/reducers/membersReducer';
import { newsReducer } from './redux/reducers/newsReducer';
import noticeReducer from './redux/reducers/noticeReducer';
import galleryReducer from './redux/reducers/galleryReducer';
import { chatReducer } from './redux/reducers/chatReducer';
import { positionReducer } from './redux/reducers/positionReducers';




const reducer = combineReducers({

    auth: authReducer,
    forgotPassword: forgotPasswordReducer,
    batch: batchReducer,
    position:positionReducer,
    department: departmentsReducer,
    jobFields: jobFieldReducer,
    jobOrgs: jobOrgReducer,
    blogs: blogReducer,
    blogComment: blogCommentReducer,
    events: eventReducer,
    jobPosts: jobPostReducer,
    committee: committeeReducer,
    settings: settingsReducer,
    members: membersReducer,
    news: newsReducer,
    notices: noticeReducer,
    galleries: galleryReducer,
    chats: chatReducer,
})


let initialState = {
    user: {
        name: '',
        email: '',
    }
}
window.__WEB_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
const middlware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middlware)))

export default store;