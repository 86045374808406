import React from 'react'
import HeaderOne from '../common/header/HeaderOne'

const VerifyEmail = () => {
  return (
    <div>
   <HeaderOne />
   <hr />
    <div>
        <p className='text-center p-5'>Verify Your Email !!</p>
    </div>

   
  </div>
    
  )
}

export default VerifyEmail