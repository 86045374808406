 import React, { useEffect } from 'react';
 import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
 import ScrollToTop from "react-scroll-to-top";
 import WOW from 'wowjs';
 import { FaAngleUp } from "react-icons/fa";


import HomeDefault from "./pages/Home";
 import About from "./pages/About";
 import Committee from "./pages/Committee";
 import Gallery from "./pages/Gallery";
 import Events from "./pages/Events";
 import EventDetails from "./pages/EventDetails";
 import BlogVersionOne from "./pages/Blogs";
 import BlogDetails from "./pages/BlogDetails";
 import Contact from "./pages/Contact";
 import Error from "./pages/Error";
 import Member from "./pages/Member";
 import SubscriptionPlan from './pages/SubscriptionPlan';
 import JobPost from './pages/JobPost';
 import JobPostDetails from './pages/JobPostDetails';
import ProfileUser from './pages/ProfileUser';
 import MemberProfile from './pages/MemberProfile';
 import Login from './pages/Login';
 import ForgotPassword from './pages/ForgotPassword';
 import SetPassword from './pages/SetPassword';
 import Register from './pages/Register';
import Alumni from './pages/Alumni';
import Message from './pages/Message';
import VerifyEmail from './pages/VerifyEmail';


 import "swiper/css";
 import "swiper/css/pagination";
 import "swiper/css/navigation";
 import 'swiper/css/effect-fade';


import News from './pages/News';
import NewsDetails from './pages/NewsDetails';
import Notice from './pages/Notice';
import History from './pages/History';
import RuleBook from './pages/RuleBook';
import EcCouncil from './pages/EcCouncil';
import { useDispatch } from 'react-redux';

 import { getSettings } from './redux/actions/settingsActions';
 import { loadUser } from './redux/actions/userActions';
 import { getBatches } from './redux/actions/batchAction';
 import { getDepartments } from './redux/actions/departmentAction';
 import { getJobFields } from './redux/actions/jobFieldAction';
 import { getJobOrgs } from './redux/actions/jobOrgAction';
 import { getPositions } from './redux/actions/positionAction';
 import GalleryDetails from './pages/GalleryDetails';
 import NoticeView from './pages/NoticeView';
import PrivateRoute from './route/PrivateRoute';
import Loader from './common/Loader/Loader';
import Verification from './components/user/Verification';





 function App() {
   const dispatch = useDispatch();

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
    
    dispatch(loadUser());
    dispatch(getSettings());
    dispatch(getBatches());
    dispatch(getDepartments());
    dispatch(getJobFields());
    dispatch(getJobOrgs());
    dispatch(getPositions());
    
  }, [dispatch]);



  return (
    <>
    <Loader />
    <Router>
      <Routes>
        <Route path={`/`} exact element={<HomeDefault />} />
        <Route path={`/about`} exact element={<About />} />
        <Route path={`/committee`} exact element={<Committee />} />
        <Route path={`/galleries`} exact element={<Gallery />} />
        <Route path={`/galleries/:id`} exact element={<GalleryDetails />} />
        <Route path={`/Events`} exact element={<Events />} />
        <Route path={`/Events/:id`} exact element={<EventDetails />} />
        <Route path={`/blogs`} exact element={<BlogVersionOne />} />
        <Route path={`/blogs/:id`} exact element={<BlogDetails />} />
        <Route path={`/contact`} exact element={<Contact />} />
        <Route path={`/verify-email`} exact element={<VerifyEmail />} />
        <Route
          path="/members"
          element={<PrivateRoute>
            <Member />
          </PrivateRoute>} />
        <Route
          path="/members/:id"
          element={<PrivateRoute>
            <MemberProfile />
          </PrivateRoute>} />

        <Route
          path="/jobs"
          element={<PrivateRoute>
            <JobPost />
          </PrivateRoute>} />
        <Route
          path="/jobs/:id"
          element={<PrivateRoute>
            <JobPostDetails />
            </PrivateRoute>} />
          
          <Route path="/profile"
          element={<PrivateRoute>
            <ProfileUser />
            </PrivateRoute>} />
          
          <Route path="/messages"
            element={<PrivateRoute>
              <Message />
            </PrivateRoute>} />

        <Route path={`/all-alumni`} exact element={<Alumni />} />
        <Route path={`/news`} exact element={<News />} />
        <Route path={`/news/:id`} exact element={<NewsDetails />} />
        <Route path={`/notices`} exact element={<Notice />} />
        <Route path={`/notices/:id`} exact element={<NoticeView />} />
        <Route path={`/history`} exact element={<History />} />
        <Route path={`/rule-book`} exact element={<RuleBook />} />
        <Route path={`/ec-council`} exact element={<EcCouncil />} />
        <Route path={`/subscription-plan`} exact element={<SubscriptionPlan />} />

        {/* Authentication */}
        <Route path={"/login"} exact element={<Login />} />
        <Route path={"/forgot-password"} exact element={<ForgotPassword />} />
        <Route path={"/reset/:token"} exact element={<SetPassword />} />
        <Route path={"/register"} exact element={<Register />} />
        <Route path='/email/verify/success' exact element={<Verification/>}/>

        <Route path={`/*`} exact element={<Error />} />
      </Routes>
      <ScrollToTop
        className="scrollUp"
        smooth
        top="1500"
        component={<FaAngleUp />} />
      
          
    </Router></>
   );
 }

export default App;




