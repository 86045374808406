import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllJobPosts, clearErrors } from '../../redux/actions/jobPostAction';
import Loader from '../../common/Loader/Loader';
import { useAlert } from 'react-alert';
import Pagination from 'react-js-pagination';
import { removeHTMLTags } from '../../utils/removeHTMLTags';


const JobPostOne = () => {
  const alert = useAlert();
  const { allJobPosts, error, loading } = useSelector(state => state.jobPosts);
  const dispatch = useDispatch();
  const { data: jobPostsData, meta } = allJobPosts;
  const [activePage, setActivePage] = useState(meta?.current_page || 1);


  useEffect(() => {
    dispatch(getAllJobPosts(activePage));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

  }, [activePage, dispatch])


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  let publicUrl = process.env.PUBLIC_URL + '/'


  return (
    <div>
      {
        loading ? <Loader /> : (
          <section className="blog-page-V-1">
            <div className="container">
              {jobPostsData ?
                <div>
                  <div className="row">


                    {jobPostsData?.map((jobpost,index) => (
                      <div key={index} className="col-xl-3 col-lg-3 col-md-4 wow fadeInUp " data-wow-delay="100ms">


                        <div className="testimonial-one__single  py-4 my-2">

                          <div className="testimonial-one__img">

                            {jobpost?.image ?
                              <img src={jobpost?.image} alt={jobpost?.title} className=" p-1 user-profile-image" />
                              :
                              <img src={publicUrl + "assets/images/deafult_image/jobpost.jpg"} alt="job post" className=" p-1 user-profile-image" />


                            }
                          </div>

                          {jobpost?.title ?
                            <h5 className="py-2">{jobpost?.title?.slice(0, 30)}
                              {jobpost?.title?.length >= 30 ? ".." : ''}
                            </h5>
                            : <p> -- </p>
                          }

                          {jobpost?.description ?
                            <p>
                              {removeHTMLTags(jobpost?.description)?.slice(
                                0,
                                110,
                              )}
                              ...
                            </p>
                            : <p> -- </p>
                          }


                          <Link to={`/jobs/${jobpost?.id}`} className="blog-link">
                            <button
                              type="submit"
                              className="thm-btn comment-form__btn my-2 px-5 py-3"
                            >
                              View Job
                            </button>
                          </Link>
                        </div>

                      </div>
                    ))}

                  </div>
                  <div className="pagination-div">

                    {meta?.total > meta?.per_page && (
                      <div className="pagination-div">
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={meta?.per_page}
                          totalItemsCount={meta?.total || 0}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          activeLinkClass="active-pagination-button"
                        />
                      </div>
                    )}
                  </div>
                </div>
                :
                <p>Job Post is Empty !!</p>}

            </div>
          </section>
        )}
    </div>
  );
}

export default JobPostOne;
