import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';

import { getPositions } from '../../redux/actions/positionAction';

import { register, clearErrors } from '../../redux/actions/userActions';
import Loader from "../../common/Loader/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Creatable from 'react-select/creatable';
import { addField, getJobFields } from "../../redux/actions/jobFieldAction";
import { addOrg, getJobOrgs } from "../../redux/actions/jobOrgAction";
import { addPosition } from "../../redux/actions/positionAction";


const Register = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { loading, error,message } = useSelector(state => state.auth);
  const { batches } = useSelector(state => state.batch);
  const { departments } = useSelector(state => state.department);
  const { jobFields } = useSelector(state => state.jobFields);
  const { jobOrgs } = useSelector(state => state.jobOrgs);
  const { positions } = useSelector(state => state.position);


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [batchId, setBatchId] = useState("");
  const [studentID, setStudentID] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [avatar, setAvatar] = useState("");
  const [jobField, setJobField] = useState("");
  const [jobOrganization, setJobOrganization] = useState("");
  const [jobPosition, setJobPosition] = useState("");
  const [address, setAddress] = useState("");

  const navigate =useNavigate();



  const jobFieldOptions = jobFields && jobFields.length > 0
    ? jobFields?.map((option) => ({
      value: option?.id,
      label: option?.title,
    })) : [];

  const jobOrganizationOptions = jobOrgs && jobOrgs.length > 0
    ? jobOrgs?.map((option) => ({
      value: option?.id,
      label: option?.title,
    })) : [];
  
  const positionOptions = positions && positions.length > 0
    ? positions?.map((option) => ({
      value: option?.id,
      label: option?.title,
    })) : [];

  const handleSubmit = (e) => {
    e.preventDefault();
  if(password!==confirmPassword)
    {
      alert.error("Password and Confirm Password do not match");
      return;
    }

    if(name==='' || email==='' ||departmentId==='' || batchId===''||studentID==='' || password==='' || confirmPassword==='' || phone==='' || role==='' || address===''  )
      {
        alert.error("Please fill all the fields");
        return;
      }
    if(role==='alumni')
      {
        if(jobField==='' || jobOrganization==='' || jobPosition==='' )
          {
            alert.error("Please fill all the fields");
            return;
          }
      }


    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("department_id", departmentId);
    formData.append("batch_id", batchId);
    formData.append("studentID", studentID);
    formData.append("password", password);
    formData.append("confirmPassword", confirmPassword);
    formData.append("phone", phone);
    formData.append("role", role);
    formData.append("address", address);
    formData.append("avatar", avatar);

    if (jobField) {
      formData.append("jobField", jobField);
    }
    if (jobOrganization) {
      formData.append("jobOrganization", jobOrganization);
    }
    if (jobPosition)
    {
      formData.append("positionId", jobPosition);
      }


      dispatch(register(formData))

      setName("");
      setEmail("");
      setDepartmentId("");
      setBatchId("");
      setStudentID("");
      setPassword("");
      setConfirmPassword("");
      setPhone("");
      setRole("");
      setAddress("");
      setAvatar("");
      setJobField("");
      setJobOrganization("");
      setJobPosition("");
    
      
       
      
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
     if(message)
      {
        navigate('/verify-email');

        dispatch(clearErrors());

      }
  
  }, [error, alert, dispatch,message]);


  return (
    <>
      <section className=" mt-5 donate-now__personal-info-box">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 mb-4 col-lg-6">
              <div className="contact-page__left">
              
                {loading ? (
                  <Loader />
                ) : (
                  <div className="contact-page__form">


                    <div className="contact-page__form">
                      <form onSubmit={handleSubmit} className="comment-one__form contact-form-validated" noValidate>
                        <div className="row">

                          <div className="col-xl-12  mb-4">
                              <div className="form-group">
                                <label for="#fullname">Full name</label>
                                <input
                                className="form-control" 
                                type="text"
                                value={name}
                                onChange={(e) => setName(e?.target?.value)}
                              />
                            </div>
                            </div>
                            
                          <div className="col-xl-12 mb-4">
                            <div  className="form-group">
                              <label for="#email">Email</label>
                                <input
                                  className="form-control" 
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e?.target?.value)}
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 mb-4">
                            <div className="row">
                              <div className="col-xl-6 mb-4">
                                <div  className="form-group">
                                    <label>Department</label>
                                    <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={departmentId}
                                    onChange={(e) => setDepartmentId(e?.target?.value)}
                                  >
                                    <option key="Department" value="Department">
                                      Department
                                    </option>
                                    {departments &&
                                      departments?.map((department,index) => (
                                        <option key={index} value={department?.id}>
                                          {department?.title}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div  className="form-group">
                                    <label>Batch</label>
                                    <select
                                      className="form-select"
                                    aria-label="Default select example"
                                    value={batchId}
                                    onChange={(e) => setBatchId(e?.target?.value)}
                                  >
                                    <option key="Batch">Batch</option>
                                    {batches &&
                                      batches?.map((batch,index) => (
                                        <option key={index} value={batch?.id}>
                                          {batch?.session}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-xl-6 ">
                                <div  className="form-group">
                                  <label for="#studentId">Student Id</label>
                                    <input
                                      className="form-control" 

                                    type="text"
                                    value={studentID}
                                    onChange={(e) => setStudentID(e?.target?.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-xl-6 ">
                                <div  className="form-group">
                                    <label>Role</label>
                                    <select
                                      className="form-select"
                                    aria-label="Default select example"
                                    value={role}
                                    onChange={(e) => setRole(e?.target?.value)}
                                  >
                                    <option key="role" >Role</option>
                                    <option key="student" value="student" >Student</option>
                                    <option key="alumni" value="alumni">Alumni</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                            {role === "alumni" &&
                          <>
                          <div className="col-xl-12 mb-4">
                                <div className="form-group">
                                  <label>Job Field</label>
                                  <Creatable
                                   
                                options={jobFieldOptions}
                                onChange={(selectedOption) => setJobField(selectedOption ? selectedOption?.value : '')}
                                onCreateOption={async (inputValue) => {
                                  await dispatch(addField(inputValue));

                                  await dispatch(getJobFields());
                                }}
                                value={jobFieldOptions?.find((option) => option?.label === jobField)}

                                isSearchable />
                            </div>
                              </div>
                              <div className="col-xl-12 mb-4">
                                <div className="form-group">
                                  <label>Job Organization</label>
                              <Creatable
                                options={jobOrganizationOptions}
                                onChange={(selectedOption) => setJobOrganization(selectedOption ? selectedOption?.value : '')}
                                onCreateOption={async (inputValue) => {
                                  await dispatch(addOrg(inputValue));
                                  await dispatch(getJobOrgs());
                                }}
                                value={jobOrganizationOptions?.find((option) => option?.label === jobOrganization)}
                                isSearchable />
                            </div>
                              </div>
                              <div className="col-xl-12 mb-4">
                                <div className="form-group">
                                  <label>Designation</label>
                                  <Creatable
                                    options={positionOptions}
                                    onChange={(selectedOption) => setJobPosition(selectedOption ? selectedOption?.value : '')}
                                    onCreateOption={async (inputValue) => {
                                      await dispatch(addPosition(inputValue));
                                      await dispatch(getPositions());
                                    }}
                                    value={positionOptions?.find((option) => option?.label === jobPosition)}
                                    isSearchable />
                                </div>
                              </div>
                              </>
                            }
                          <div className="col-xl-12 mb-4">
                            <div  className="form-group">
                              <label for="#image">Image</label>
                                {/* <input
                                  className="form-control" 

                                type="file"
                                id="avatar"
                                onChange={(e) => setAvatar(e.target.files[0])}
                              /> */}
                                
                                <input
                                  type="file"
                                  className="form-control-file"
                                  onChange={(e) => setAvatar(e?.target?.files[0])}
                                />
                              {/* {avatar && (
                                <img src={URL.createObjectURL(avatar)} alt="Avatar Preview" className=" p-1 " style={{ maxWidth: '100px', marginTop: '10px' }} />
                              )} */}
                            </div>
                          </div>

                          <div className="col-xl-12 mb-4">
                            <div  className="form-group">
                              <label for="#phone">Phone</label>
                                <input
                                  className="form-control" 

                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e?.target?.value)}
                              />
                              </div>
                          </div>

                            <div className="col-xl-12 mb-4">
                                <div className="form-group">
                                  <label for="#address">Address</label>

                                  <textarea
                                    className="form-control" 
                                  id="address"
                                  value={address}
                                  onChange={(e) => setAddress(e?.target?.value)}
                                />
                                {address && <div>Entered Address: {address}</div>}
                              </div>
                            </div>

                          <div className="col-xl-6 mb-4">
                            <div  className="form-group">
                              <label for="#password">Password</label>
                                <input
                                  className="form-control" 

                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e?.target?.value)}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 mb-4">
                            <div  className="form-group">
                                <label for="#confirmPassword">Confirm Password</label>
                                <input
                                  className="form-control" 

                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e?.target?.value)}
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 mb-4">
                            <div className="comment-form__btn-box d-flex justify-content-center">
                              <button type="submit" className="thm-btn comment-form__btn full_button">
                                Register
                              </button>
                            </div>
                          </div>
                          <div className="col-xl-12 mb-4 pb-5 pt-4 mb-5">
                            <div className="row justify-content-center pb-5 mb-5">
                              <div className="col-xl-6 mb-4 d-flex justify-content-center">
                                <p>
                                  Already have an account?{" "}
                                  <Link to="/login" className="text-success py-5 mb-5 text-center">
                                    Login
                                  </Link>{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Register;