import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getNews } from "../../redux/actions/newsAction";
import Loader from "../../common/Loader/Loader";
import Pagination from "react-js-pagination";

const NewsComponent = () => {
  const alert = useAlert();
  const { allNews, error, loading } = useSelector(state => state.news);
  const dispatch = useDispatch();
  const { data: newsData, meta } = allNews;
  const [activePage, setActivePage] = useState(meta?.current_page || 1);

  useEffect(() => {
    dispatch(getNews(activePage));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

  }, [activePage, dispatch])


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div>
      {
        loading ? <Loader /> : (
          <section className="blog-three">
            {newsData ?
              <div>
                <div className="container">
                  <div className="row">
                    {newsData?.map((news, index) =>
                      index === 0 ? (
                        <div
                          key={index}
                          className="col-xl-6 col-lg-6 wow fadeInUp p-2 pb-4 "
                          data-wow-delay="100ms"
                        >
                          <Link to={`/news/${news?.id}`} className="news_big_title">
                         

                            <div className="blog-three__single mb-0">
                              <div className="blog-three__img ">
                             

                                {news?.image ?
                                  <img src={news?.image} alt={news?.title}  />
                                  :
                                  <img src={publicUrl + "assets/images/deafult_image/news.jpeg"} alt="job post"  />


                                }
                              </div>
                              <div className="blog-three__content news_big_card">
                                <ul className="list-unstyled blog-three__meta">
                                  <li>

                                    <span className="news_big_clander">
                                      <i className="far fa-calendar pr-2"></i>
                                      <span className="px-1">
                                        {news?.date ? news.date : "--"}
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                                <h3 className="blog-three__title ">
                                  <span className="news_big_title">
                                    <span> {news?.title ? news?.title : "--"}</span>

                                  </span>
                                </h3>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className="col-xl-3 col-lg-3 wow fadeInUp p-2 pb-4"
                          data-wow-delay="100ms"
                        >
                          <Link to={`/news/${news?.id}`} className="news_big_title">
                           
                            <div className="blog-three__single mb-0">
                              <div className="blog-three__img">
                                  {news?.image ?
                                    <img src={news?.image} alt={news?.title} />
                                    :
                                    <img src={publicUrl + "assets/images/deafult_image/news.jpeg"} alt="job post" />
                                  }
                              </div>
                              <div className="blog-three__content">
                                <ul className="list-unstyled blog-three__meta">
                                  <li>

                                    <span>
                                      <i className="far fa-calendar pr-2"></i>
                                        <span className="px-1">
                                          {news?.date ? news.date : "--"}
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                                <h3 className="blog-three__title">
                                    <span> {news?.title ? news?.title :"--"}</span>
                                </h3>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <div className="pagination-div">

                  {meta?.total > meta?.per_page && (
                    <div className="pagination-div">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={meta?.per_page}
                        totalItemsCount={meta?.total || 0}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        activeLinkClass="active-pagination-button"
                      />
                    </div>
                  )}
                </div>
              </div>
             :
            <p>News page is Empty!!</p>
            }
           
          
          </section>
        )}
    </div>
  );
};

export default NewsComponent;
