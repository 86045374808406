import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getSingleJobPost,
} from "../../redux/actions/jobPostAction";
import Loader from "../../common/Loader/Loader";
import { imageUrl } from "../../utils/baseUrl";

const SingleJobPost = () => {
  const { id } = useParams();
  const { error, loading, singleJobPost } = useSelector(
    (state) => state.jobPosts,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleJobPost(id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, id]);

  if (loading) {
    return <Loader />;
  }

  if (!singleJobPost) {
    return <div>No Job Post found.</div>;
  }

  let publicUrl = process.env.PUBLIC_URL + '/'

;

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <section className="blog-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="blog-details__left">
                  <div className="blog-details__content">
                    <div className="d-flex justify-content-center align-items-center">

                      {singleJobPost?.data?.image ?
                       <img className="w-50  " src={singleJobPost?.data?.image} alt={singleJobPost?.data?.title} />
                        :
                        <img src={publicUrl + "assets/images/deafult_image/jobpost.jpg"} alt="job post" />


                      }
                    </div>

                    {singleJobPost?.data?.title ?
                      <h4 className="blog-details__title">{singleJobPost?.data?.title}</h4>

                      : <p> -- </p>
                    }
                    {singleJobPost?.data?.date ?
                      <p className="pb-2">Date :
                        {singleJobPost?.data?.date}
                      </p>

                      : <p> -- </p>
                    }
                    <h4 className="py-3">Description </h4>
                    {singleJobPost?.data?.description ?
                      <p
                        className="blog-details__text-1 pb-5"
                        dangerouslySetInnerHTML={{ __html: singleJobPost?.data?.description }}
                      ></p>
                      : <p> -- </p>
                    }



                  </div>

                  <div className="author-one p-4">
                      <div className="author-one__image">
                        <Link to={`/members/${singleJobPost?.data?.user_id}`}>
                          {singleJobPost?.data?.user_avater ?
                            <img src= { imageUrl + '/' + singleJobPost?.data?.user_avater} alt={singleJobPost?.data?.user_name} />
                            :

                            <img src={publicUrl + "assets/images/deafult_avater/graduate-avater.jpg"} alt="Member" />

                          }
                        </Link>
                      
                      </div>
                     
                    <div className="author-one__content">
                        <p className="text-base-color" >Created by</p>
                        <Link to={`/members/${singleJobPost?.data?.user_id}`}>
                          <h3>{singleJobPost?.data?.user_name}</h3>

                        </Link>

                    </div>
                    </div>
                    
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default SingleJobPost;
