import axios from 'axios';
import {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,

  CLEAR_ERRORS,
} from '../constants/settingConstant';

import { baseUrl } from '../../utils/baseUrl';

export const getSettings = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SETTINGS_REQUEST });

    const { data } = await axios.get(`${baseUrl}/settings`);
    dispatch({ type: GET_SETTINGS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SETTINGS_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};


export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
