import axios from 'axios';

import {
  ALL_EVENT_REQUEST,
  ALL_EVENT_SUCCESS,
  ALL_EVENT_FAIL,
  SINGLE_EVENT_REQUEST,
  SINGLE_EVENT_SUCCESS,
  SINGLE_EVENT_FAIL,
  PAST_EVENT_REQUEST,
  PAST_EVENT_SUCCESS,
  PAST_EVENT_FAIL,
  UPCOMING_EVENT_REQUEST,
  UPCOMING_EVENT_SUCCESS,
  UPCOMING_EVENT_FAIL,
  CLEAR_ERRORS,
} from '../constants/eventCostant';
import { baseUrl } from '../../utils/baseUrl';

// Fetch all events
export const getAllEvent = (pageNumber) => async (dispatch) => {

  try {
    dispatch({ type: ALL_EVENT_REQUEST });

    const { data } = await axios.get(`${baseUrl}/events?page=${pageNumber}`);

    dispatch({
      type: ALL_EVENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_EVENT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// Fetch a single event by ID
export const getSingleEvent = (eventId) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_EVENT_REQUEST });

    const { data } = await axios.get(`${baseUrl}/events/${eventId}`);
    dispatch({
      type: SINGLE_EVENT_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: SINGLE_EVENT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getPastEvents = (pageNumber) => async (dispatch) => {
  try {
    dispatch({ type: PAST_EVENT_REQUEST });

    const { data } = await axios.get(`${baseUrl}/events/past-events?page=${pageNumber}`);

    dispatch({
      type: PAST_EVENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PAST_EVENT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const getUpcomingEvents = () => async (dispatch) => {
  try {
    dispatch({ type: UPCOMING_EVENT_REQUEST });

    const { data } = await axios.get(`${baseUrl}/events/upcoming-events`);

    dispatch({
      type: UPCOMING_EVENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPCOMING_EVENT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};


// Clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
