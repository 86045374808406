import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {  clearErrors, getPastEvents, getUpcomingEvents } from "../../redux/actions/eventAction";
import Loader from "../../common/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { convertDateFormat } from "../../utils/timeDateFormate";
import { removeHTMLTags } from "../../utils/removeHTMLTags";
import Pagination from "react-js-pagination";

const Events = () => {
  const { error, loading, pastEvents, upcomingEvents } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const { data: pastEvenData, meta } = pastEvents;
  const { data: upcomingEvenData } = upcomingEvents;



  const [activePage, setActivePage] = useState(meta?.current_page || 1);
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(getPastEvents(activePage));
    dispatch(getUpcomingEvents());
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [activePage, dispatch]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <section className="events-area_event events-area bg-color ptb-100">
          <div className="container py-5">
            <div className="row align-items-center">
              <div>
                <div className="d-flex ">
                  <ul className="main-menu__list relevent d-flex  list-unstyled ">
                    <li>
                      <Link to={`#`} className="event-teb">
                        <p
                          className={`${active ? "active  " : ""} px-3 pb-3 `}
                          onClick={() => setActive(true)}
                        >
                          Recent Event
                        </p>
                      </Link>
                    </li>
                    <li>
                      <Link to={`#`} className="event-teb">
                        <p
                          className={`${active ? "" : "active "} px-3 pb-3 `}
                          onClick={() => setActive(false)}
                        >
                          {" "}
                          Past Event
                        </p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
              <div className="">
                <div className="events-content  events-content_event">
                  {active && (
                    <ul className="events-list">
                      {upcomingEvenData?.map((event, index) => (
                        <li key={index} className="events-item">
                          <div className="d-flex">
                            <div className="events-date">
                              <span className="mb-2">
                                {convertDateFormat(event?.date?.start)?.slice(
                                  0,
                                  6,
                                )}
                              </span>
                              <span>
                                {convertDateFormat(event?.date?.start)?.slice(7)}
                              </span>
                            </div>

                            <div>
                              <h3>
                                <Link to={`/events/${event?.id}`}>
                                  {event?.title}
                                </Link>
                              </h3>
                              <p>
                                {removeHTMLTags(event?.description)?.slice(
                                  0,
                                  150,
                                )}
                                ...
                              </p>
                              <Link
                                to={`/events/${event?.id}`}
                                className="read-more"
                              >
                                Find out more
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ marginLeft: "0.4rem" }}
                                />
                              </Link>
                            </div>
                            <div className="event_image">
                              <img src={event?.image} className="" alt="" />
                            </div>
                          </div>
                          <hr />
                        </li>
                      ))}
                      {upcomingEvenData?.length === 0 && (
                        <p>Upcomming Event List is empty</p>
                      )}
                    </ul>
                  )}

                  {active === false && (
                    <ul className="events-list">
                      {pastEvenData?.map((event, index) => (
                        <li key={index} className="events-item">
                          <div className="d-flex">
                            <div className="events-date">
                              <span className="mb-2">
                                {convertDateFormat(event?.date?.start)?.slice(
                                  0,
                                  6,
                                )}
                              </span>
                              <span>
                                {convertDateFormat(event?.date?.start)?.slice(7)}
                              </span>
                            </div>

                            <div>
                              <h3>
                                <Link to={`/events/${event?.id}`}>
                                  {event?.title}
                                </Link>
                              </h3>
                              <p>
                                {removeHTMLTags(event?.description)?.slice(
                                  0,
                                  150,
                                )}
                                ...
                              </p>
                              <Link
                                to={`/events/${event?.id}`}
                                className="read-more"
                              >
                                Find out more
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ marginLeft: "0.4rem" }}
                                />
                              </Link>
                            </div>
                            <div className="event_image">
                              <img src={event?.image} className="" alt="eventimage" />
                            </div>
                          </div>
                          <hr />
                        </li>
                      ))}
                      {pastEvenData?.length === 0 && (
                        <p>Past Event List is empty</p>
                      )}
                    </ul>
                  )}
                </div>
              </div>
              {active === false &&
                meta?.total > meta?.per_page && (
                  <div className="pagination-div">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={meta?.per_page}
                      totalItemsCount={meta?.total || 0}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      activeLinkClass="active-pagination-button"
                    />
                  </div>
                )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Events;
