import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import FooterTwo from "../common/footer/FooterTwo";
import SubscriptionPlanComponent from "../components/subscription-plan/SubscriptionPlan";

const SubscriptionPlan = () => {
  return (
    <>
      <HeaderOne />
      <Breadcrumb heading="Subscription Plan" currentPage="Subscription Plan" />
      <SubscriptionPlanComponent />
      <FooterTwo />
    </>
  );
};

export default SubscriptionPlan;
