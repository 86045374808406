import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSingleGallery, clearErrors } from '../../redux/actions/galleryAction';
import Loader from '../../common/Loader/Loader';

const GalleryDetailsComponent = () => {
  const { id } = useParams();
  const { gallery, error, loading } = useSelector((state) => state.galleries);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleGallery(id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, id]);

  useEffect(() => {
    const $ = window.$;

    function initializeMagnificPopup() {
      if ($('.img-popup')?.length) {
        var groups = {};
        $('.img-popup')?.each(function () {
          var groupId = parseInt($(this)?.attr('data-group'), 10);

          if (!groups[groupId]) {
            groups[groupId] = [];
          }

          groups[groupId].push(this);
        });

        $.each(groups, function () {
          $(this).magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            gallery: {
              enabled: true,
            },
          });
        });
      }
    }

    initializeMagnificPopup();
  }, [gallery]); // Dependency on gallery to ensure Magnific Popup is initialized after images are loaded

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <section className="gallery-page">
          <div className="container">
            <h1 className="text-center py-5 text-bold font-weight-bold">{gallery?.title}</h1>

            <div className="row">
              {gallery?.images?.map((img, index) => (
                <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="100ms" key={index}>
                  <div className="gallery-page__single">
                    <div className="gallery-page__img">
                      <img src={img} alt={gallery?.title} />
                      <div className="gallery-page__icon">
                        <a className="img-popup" href={img}>
                          <span className="icon-plus"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default GalleryDetailsComponent;
