import axios from 'axios'
import {
    ALL_POSITION_REQUEST,
    ALL_POSITION_SUCCESS,
    ALL_POSITION_FAIL,
    CLEAR_ERRORS
} from '../constants/positionConstant'
import { baseUrl } from '../../utils/baseUrl'


export const getPositions = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_POSITION_REQUEST })

        const { data } = await axios.get(`${baseUrl}/positions`)

        dispatch({
            type: ALL_POSITION_SUCCESS,
            payload: data?.data
        })

    } catch (error) {
        dispatch({
            type: ALL_POSITION_FAIL,
            payload: error?.response?.data?.message
        })

    }
}
export const addPosition = (title) => async (dispatch) => {
    try {
        dispatch({ type: ALL_POSITION_REQUEST })

        const { data } = await axios.post(`${baseUrl}/positions`, { title })

        dispatch({
            type: ALL_POSITION_SUCCESS,
            payload: data?.data
        })

    } catch (error) {
        dispatch({
            type: ALL_POSITION_FAIL,
            payload: error?.response?.data?.message
        })

    }
}
// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}