import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import ProfileUserComponent from "../components/profile/ProfileUserComponent";

const ProfileUser = () => {
  return (
    <>
      <HeaderOne />
      <Breadcrumb heading="Profile" currentPage="Profile" />
      <ProfileUserComponent />
  
    </>
  );
};

export default ProfileUser;
