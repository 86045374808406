import {
    ALL_POSITION_REQUEST,
    ALL_POSITION_SUCCESS,
    ALL_POSITION_FAIL,
    CLEAR_ERRORS
} from '../constants/positionConstant'

export const positionReducer = (state = { positions: [] }, action) => {
    switch (action?.type) {

        case ALL_POSITION_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ALL_POSITION_SUCCESS:
            return {
                ...state,
                loading: false,
                positions: action?.payload
            }

        case ALL_POSITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action?.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }


        default:
            return state;
    }
}