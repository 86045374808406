import React, { useEffect } from 'react'
import Loader from '../../common/Loader/Loader';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, getLatestNews, getSingleNews } from '../../redux/actions/newsAction';


const NewsDetailsComponent = () => {
  const { id } = useParams();
  const { error, singleNews, loading, latestNews } = useSelector(state => state.news);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleNews(id));
    dispatch(getLatestNews());

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, id]);
  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div>
      {
        loading ? <Loader /> : (
          <section className="blog-details">

            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-7">

                  <div className="blog-details__left">

                    <div className="blog-details__img">
                      {singleNews?.image ?
                        <img src={singleNews?.image} alt={singleNews?.title} />
                        :
                        <img src={publicUrl + "assets/images/deafult_image/news?.jpeg"} alt="news" />
                      }
                      <div className="blog-details__date">


                        <p> <i className="far fa-calendar"></i>
                          {singleNews?.date ? singleNews?.date :
                            <span> -- </span>
                          }
                        </p>
                      </div>
                    </div>
                    <div className="blog-details__content">
                      <h3 className="blog-details__title">
                        {singleNews?.title ? singleNews?.title : "--"}
                      </h3>
                      {singleNews?.description ?
                        <p className="blog-details__text-1 pb-5" dangerouslySetInnerHTML={{ __html: singleNews?.description }} ></p>
                        :
                        <p> -- </p>
                      }

                    </div>

                    <div className="blog-details__bottom">
                      <div className="blog-details__social-list">
                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                        <Link to="https://www.facebook.com" target="_blank"><i className="fab fa-facebook"></i></Link>
                        <Link to="#"><i className="fab fa-pinterest-p"></i></Link>
                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                      </div>
                    </div>

                  </div>

                </div>

                <div className="blog-three">
                  <div className="container">
                    <div className="section-title text-center">
                      <h2 className="section-title__title">YOU MAY ALSO LIKE</h2>
                    </div >
                    <div className='row'>
                      {latestNews?.data?.slice(0, 4)?.map((news,index) => (
                        <div key={index} className="col-xl-3 col-lg-3 col-md-3 wow fadeInUp px-2" data-wow-delay="100ms">
                          <div className="blog-three__single">
                            <div className="blog-three__img">
                              {news?.image ?
                                <img src={news?.image} alt={news?.title} />
                                :
                                <img src={publicUrl + "assets/images/deafult_image/news.jpeg"} alt="job post" />
                              }

                            </div>
                            <div className="blog-three__content">
                              <ul className="list-unstyled blog-three__meta">
                                <li><Link to={`/news/${news?.id}`} ><i className="far fa-calendar"></i>
                                  {news?.date ? news?.date : "--"}

                                </Link></li>
                              </ul>
                              <h3 className="blog-three__title"><Link to={`/news/${news?.id}`} >
                                {news?.title ? news?.title : "--"}
                              </Link></h3>

                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
    </div>)
}

export default NewsDetailsComponent