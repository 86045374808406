import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { updateProfile, updatePassword, clearErrors, updateProfileImage } from "../../redux/actions/userActions";
import { useAlert } from 'react-alert';
import { imageUrl } from "../../utils/baseUrl";

const ProfileUserComponent = () => {
  const { error, user,message } = useSelector(state => state.auth);
  const [image, setImage] = useState('');

  const dispatch = useDispatch();
  const alert = useAlert();

  const [profileData, setProfileData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    studentID: user?.studentID || "",
    address: user?.address || "",
  });

  const [passwordData, setPasswordData] = useState({
    old_password: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (message) {
      alert.success(message);
      dispatch(clearErrors());
    }
  }, [error, message, alert, dispatch]);

  useEffect(() => {
    if (user) {
      setProfileData({
        name: user.name,
        email: user.email,
        phone: user.phone,
        studentID: user.studentID,
        address: user.address,
      });
    }
  }, [user]);

  const handleProfileSave = () => {
    dispatch(updateProfile(profileData));
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("avatar", image);
   
    dispatch(updateProfileImage(formData));   
  };

  const handlePasswordUpdate = () => {
    dispatch(updatePassword(passwordData));
    setPasswordData({
      old_password: "", password: ""
    });
  };

  const handleInputChange = (e, field, dataSetter) => {
    dataSetter(prevData => ({ ...prevData, [field]: e.target.value }));
  };

  const toggleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div className="container py-5">
      <div className="main-body user-profile-card">
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-column align-items-center text-center">
                  {user?.avatar === null ?
                    <img src={publicUrl + "assets/images/deafult_avater/student.jpeg"} alt="Member" className=" p-1 user-profile-image" />
                    :
                    <img src={`${imageUrl}/${user?.avatar}`} alt="Member " className=" p-1 user-profile-image" />
                  }
                  <form onSubmit={submitForm} className="p-3">
                    <input
                      type="file"
                      className="form-control-file mb-3"
                      onChange={(e) => setImage(e?.target?.files[0])}
                    />
                    <button className="thm-btn comment-form__btn px-4 py-2" type="submit">
                      Update Image
                    </button>
                  </form>
                  <div className="mt-3">
                    <h4>{user?.name}</h4>
                    <p className="text-secondary mb-1">
                      <p>{user?.designation ? user?.designation : "--"}</p>
                    </p>
                    <p className="text-muted font-size-sm">
                      {user?.organization ? user?.organization : "--"}
                    </p>
                  </div>
                </div>
              </div>
              {user?.role !== "student" &&
                <Link to="http://alumniapi.cuet.ac.bd" className="px-2 pt-0" target="blank">
                  <button className="thm-btn comment-form__btn  py-2  mb-3 w-100">
                    Alumni Dashboard
                  </button>
                </Link>
              }
            </div>
          </div>
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Full Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    <input
                      type="text"
                      className="form-control"
                      value={profileData?.name}
                      onChange={(e) => handleInputChange(e, "name", setProfileData)} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Email</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      value={profileData?.email}
                      onChange={(e) => handleInputChange(e, "email", setProfileData)} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Phone</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    <input
                      type="text"
                      className="form-control"
                      value={profileData?.phone}
                      onChange={(e) => handleInputChange(e, "phone", setProfileData)} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">ID</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      value={profileData?.studentID}
                      onChange={(e) => handleInputChange(e, "studentID", setProfileData)} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Address</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    <input
                      type="text"
                      className="form-control"
                      value={profileData?.address}
                      onChange={(e) => handleInputChange(e, "address", setProfileData)} />
                  </div>
                </div>
                <div className="py-3 profile-button-div d-flex justify-content-center align-items-center">
                  <Link
                    to="#"
                    className="thm-btn comment-form__btn px-4 py-2"
                    onClick={handleProfileSave}
                  >
                    Save
                  </Link>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Previous Password</h6>
                  </div>
                  <div className="col-sm-9 text-secondary d-flex align-items-center">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      value={passwordData?.old_password}
                      onChange={(e) => handleInputChange(e, "old_password", setPasswordData)}
                    />
                    <FontAwesomeIcon
                      className={`toggle-password-icon ${showPassword ? 'eye-slash' : 'eye'}`}
                      icon={showPassword ? faEyeSlash : faEye}
                      onClick={toggleShowPassword}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <h6 className="mb-0">New Password</h6>
                  </div>
                  <div className="col-sm-9 text-secondary d-flex">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      value={passwordData?.password}
                      onChange={(e) => handleInputChange(e, "password", setPasswordData)}
                    />
                  </div>
                </div>
                <div className="py-3 profile-button-div d-flex justify-content-center align-items-center">
                  <button className="thm-btn comment-form__btn px-4 py-2" onClick={handlePasswordUpdate}>
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUserComponent;
