import axios from 'axios';
import {
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  SINGLE_MEMBER_REQUEST,
  SINGLE_MEMBER_SUCCESS,
  SINGLE_MEMBER_FAIL,

  CLEAR_ERRORS,
} from '../constants/membersConstant';
import { baseUrl } from '../../utils/baseUrl';


export const getMembers = (pageNumber = 1, filters = {}, search_table) => async (dispatch) => {
  try {
    dispatch({ type: GET_MEMBERS_REQUEST });

    const queryParams = new URLSearchParams();

    Object?.entries(filters)?.forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        queryParams?.append(key, value);
      }
    });

    const queryString = queryParams?.toString()?.replace(/%2C/g, ',');
    if (search_table !== '') {
      queryParams?.append('search_table', search_table);
    }
    let apiUrl = pageNumber === 'all' ? `${baseUrl}/members?${queryString ? `${queryString}` : ''}` : `${baseUrl}/members?${queryString ? `${queryString}&` : ''}page=${pageNumber}`;

    const { data } = await axios.get(apiUrl);

    dispatch({ type: GET_MEMBERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_MEMBERS_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};


export const getSingleMember = (memberId) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_MEMBER_REQUEST });

    const { data } = await axios.get(`${baseUrl}/members/${memberId}`);

    dispatch({ type: SINGLE_MEMBER_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({
      type: SINGLE_MEMBER_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};