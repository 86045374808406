import React, { useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../utils/baseUrl';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

const SetPassword = ({ token }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const alert = useAlert();
  const navigate =useNavigate();
  const handlePasswordChange = (e) => {
    setPassword(e?.target?.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e?.target?.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if password and confirm password fields are empty
    if (!password || !confirmPassword) {
      alert.error('Please fill in both password fields.');
      return;
    } else if (password !== confirmPassword) {
      // Check if password and confirm password match
      alert.error('Passwords do not match. Please try again.');
      return;
    } else {

      setErrorMessage('');

      try {
        const response = await axios.post(`${baseUrl}/auth/password/confirm-reset`, {
          password,
          password_confirmation: confirmPassword,
          token
        });

        if (response?.data?.message) {
          alert.success(response.data.message);
        } else {
          setErrorMessage('Invalid email or password');
        }
      } catch (error) {
        setErrorMessage(
          error.response?.data?.message || "An error occurred during Set Password. Please try again later."
        );
      }
    }
    navigate('/login')
  };

  return (
    <>
      <section className="p-5 mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__left">
                <div className="section-title text-left">
                  <h2 className="section-title__title text-center">Set password </h2>
                </div>
                <div className="contact-page__form pb-5 mb-5">
                  <form onSubmit={handleSubmit} className="comment-one__form contact-form-validated pb-5 mb-5" noValidate>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                          <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                        </div>
                      </div>

                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                          />
                        </div>
                      </div>

                      <div className="col-xl-12 pb-4">
                        {errorMessage && <div className="error-message text-danger">{errorMessage}</div>}
                      </div>


                      <div className="col-xl-12">
                        <div className="comment-form__btn-box d-flex justify-content-center">
                          <button type="submit" className="thm-btn comment-form__btn full_button">
                            Set Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SetPassword;
