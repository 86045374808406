import React from 'react'
import { useState,useEffect } from 'react';

import Multiselect from 'multiselect-react-dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
  multiselectContainer: {


  },
  searchBox: {

    fontSize: '15px',
    minHeight: '50px',
    border: "1px solid #BCE2D8",


  },
  inputField: {

    margin: '5px',
    color: "#6C757D",
    fontWeight: '700',

  },
  chips: {

    background: "#ECF1F5",
    fontWeight: '700',
    border: "1px solid #BCE2D8",
    color: "#6C757D",
    padding: "10px",




  },
  optionContainer: {


    border: "1px solid #ddd",

  },
  option: {

    fontWeight: '700',
    color: '#6C757D',
    background: '#FFF',
    cursor: 'pointer',

    '&:hover': {
      // Option color on hover state
      background: 'red',
      color: "blue"
    },


  },

  groupHeading: {


  }
};




const FilterOne = ({ filterTitle, dataArray, onFilterChange, selectedValues }) => {
  const options = dataArray?.map((item) => ({
    id: item?.id,
    title: item?.title ? item?.title : item?.session,
  }));
  
  const [localSelectedValues, setLocalSelectedValues] = useState(selectedValues || []);



  const customCloseIcon = <FontAwesomeIcon icon={faTimes} className="custom-close-icon crossicon" />;

  const handleSelect = (selectedList, selectedItem) => {
    setLocalSelectedValues(selectedList);
    const selectedIds = selectedList?.map((item) => item?.id);
    if (onFilterChange) {
      onFilterChange(selectedIds);
    }
  };

  const handleRemove = (removedList, removedItem) => {
    setLocalSelectedValues(removedList);
    const updatedIds = removedList?.map((item) => item?.id);
    if (onFilterChange) {
      onFilterChange(updatedIds);
    }
  };
  

  useEffect(() => {
    setLocalSelectedValues(selectedValues || []);
  }, [selectedValues]);

  return (
    <div className='row py-1 '>
      <p className='py-2 filter-title'>{filterTitle}</p>
      <Multiselect
        customCloseIcon={customCloseIcon}
        style={customStyles}
        isObject={true}
        onKeyPressFn={function noRefCheck() {}}
        onRemove={handleRemove}
        onSelect={handleSelect}
        options={options}
        displayValue={"title"}
        selectedValues={localSelectedValues}
      />
    
    </div>
  );
};

export default FilterOne;
