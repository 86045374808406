import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader/Loader";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getGalleries,
} from "../../redux/actions/galleryAction";
import Pagination from "react-js-pagination";

const GalleryComponent = () => {
  const alert = useAlert();
  const { galleries, error, loading } = useSelector((state) => state.galleries);
  const dispatch = useDispatch();
  const { data: galleryData, meta } = galleries;
  const [activePage, setActivePage] = useState(meta?.current_page || 1);

  useEffect(() => {
    dispatch(getGalleries(activePage));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [activePage, dispatch]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container py-5">
            <section className="project-one">
              {galleryData ?
                <div>
                  <div className="row ">
                    {galleryData?.map((gallery, index) => (
                      <div
                        className="col-xl-4 col-lg-4 col-md-6 "
                        data-wow-delay="100ms"
                        key={index}
                      >
                        <div className="project-one__single">
                          <div className="project-one__img">
                            {gallery?.images ?
                              <img
                                src={gallery?.images[0]}
                                alt={gallery?.title}
                              />
                            :
                            <p>Event image not Found !!</p>
                            }
                           
                            <div className="project-one__content">
                              <p className="project-one__sub-title">
                                Our Best Event Gallery
                              </p>
                              <h3 className="project-one__title">
                                <Link to={`/galleries/${gallery?.id}`}>
                                  {gallery?.title}
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>
                  <div className="pagination-div">

                    {meta?.total > meta?.per_page && (
                      <div className="pagination-div">
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={meta?.per_page}
                          totalItemsCount={meta?.total || 0}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          activeLinkClass="active-pagination-button"
                        />
                      </div>
                    )}
                  </div>
                </div>
              : <p>Gallery Data is Empty!!</p>}
             
          </section>
        </div>
      )}
    </div>
  );
};

export default GalleryComponent;
