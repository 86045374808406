import React, { useEffect, useState } from "react";
import { Link ,useParams} from "react-router-dom";
import BlogQuery from "../blog-sidebar/BlogQuery";
import { Modal } from "react-bootstrap";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getSingleBlog } from "../../redux/actions/blogAction";
import Loader from "../../common/Loader/Loader";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
  postBlogComment,
  updateBlogComment,
  deleteBlogComment,
} from "../../redux/actions/blogCommentAction";

import { formatDateTimeWithAMPM } from "../../utils/timeDateFormate";
import { imageUrl } from "../../utils/baseUrl";

const SingleBlogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { error, loading, singleBlog } = useSelector((state) => state.blogs);
  const {error:commentError,message}=useSelector((state)=>state.blogComment)
  const { user } = useSelector((state) => state.auth);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [commentText, setCommentText] = useState("");
  let publicUrl = process.env.PUBLIC_URL + '/';
  const alert = useAlert();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editComment, setEditComment] = useState("");
  const [commentId, setCommentId] = useState("");



  const handleChange = (value) => {
    setCommentText(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!commentText.trim()) {
      return;
    }

    try {
      await dispatch(
        postBlogComment(commentText,user?.user?.id, id),
      );
      setCommentText("");
      setIsFormSubmitted(!isFormSubmitted);
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  const handleEdit = async () => {
    try {
      await dispatch(
        updateBlogComment(
           editComment,
           parseInt(commentId),
           user?.id,
           parseInt(id)
        ),
      );
      setCommentText("");
      setIsFormSubmitted(!isFormSubmitted);
      setShowEditModal(!showEditModal);
    } catch (error) {
      console.error("Error posting Edit comment:", error);
    }
  };
  const clickEditButton = (comment, commentId, e) => {
    e.preventDefault();
    setEditComment(comment);
    setCommentId(commentId);
    setShowEditModal(!showEditModal);
    setIsFormSubmitted(!isFormSubmitted);
  };

  const handleDelete = async (commentId) => {
    try {
      await dispatch(
        deleteBlogComment(commentId,user?.id),
      );
      setIsFormSubmitted(!isFormSubmitted);
    } catch (error) {
      console.error("Error Delete comment:", error);
    }
  };


  useEffect(() => {
    dispatch(getSingleBlog(id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (message)
    {
      alert.success(message);
      dispatch(clearErrors());
      }
  }, [dispatch, isFormSubmitted, id]);

  if (!singleBlog) {
    return <Loader />;
  }



  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <section className="blog-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-7">
                <div className="blog-details__left">
                  <div className="blog-details__img">
                      <img src={singleBlog?.image} alt={singleBlog?.title} />
                    <div className="blog-details__date">
                      <p>
                        {" "}
                        <i className="far fa-calendar"></i>{" "}
                        {new Date(singleBlog?.date).toLocaleDateString(
                          "en-US",
                          { year: "numeric", month: "long", day: "numeric" },
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="blog-details__content">
                    <ul className="blog-details__meta list-unstyled">
                      <li>
                        <i className="far fa-comment"></i>

                        <span>({singleBlog?.comments?.length})</span>
                      </li>
                    </ul>
                      <h3 className="blog-details__title">{singleBlog?.title}</h3>
                      <h4>Blog Description</h4>
                    <p
                      className="blog-details__text-1"
                      dangerouslySetInnerHTML={{
                        __html: singleBlog?.description,
                      }}
                    ></p>
                  </div>

                  <div className="blog-details__bottom">
                    <div className="blog-details__social-list">
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-facebook"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-pinterest-p"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </div>
                    </div>
                  

                    <div className="author-one p-4">
                      <div className="author-one__image">
                        <Link to={`/members/${singleBlog?.user_id}`}>
                          {singleBlog?.user_avater ?
                            <img src={ imageUrl + '/' + singleBlog?.user_avater} alt={singleBlog?.user_name} />
                            :
                            <img src={publicUrl + "assets/images/deafult_avater/graduate-avater.jpg"} alt="Member" />

                          }
                        </Link>

                      </div>

                      <div className="author-one__content">
                        <p className="text-base-color" >Created by</p>
                        <Link to={`/members/${singleBlog?.user_id}`}>
                          <h3>{singleBlog?.user_name}</h3>

                        </Link>

                      </div>
                    </div>

               

                  <div className="comment-form mb-5">
                    <h3 className="comment-form__title">Reply</h3>
                    <form
                      action="#"
                      className="comment-one__form contact-form-validated"
                      noValidate="novalidate"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="mb-4">
                            
                              <ReactQuill
                              value={commentText}
                              onChange={handleChange}
                                placeholder="Write something..."
                                style={{ height:"100px"}}
                            />
                            
                            </div>
                          

                          <div className="comment-form__btn-box mt-5 pt-3">
                            <button
                              type="submit"
                              className="thm-btn comment-form__btn"
                            >
                              Post Comment
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="editmodal">
                    <Modal
                      show={showEditModal}
                      onHide={() => setShowEditModal(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Comment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="comment-form__input-box comment-form__textarea">
                      
                          {/* <textarea
                            name="message"
                            placeholder="Edit a Comment"
                            value={editComment}
                            onChange={(e) => setEditComment(e?.target?.value)}
                            /> */}
                             <ReactQuill
              theme="snow"
              value={editComment}
              onChange={(value) => setEditComment(value)}
            />
                            
                           
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                      
                        <button
                          type="button"
                            className="thm-btn comment-form__btn full_button profile-button"
                          onClick={handleEdit}
                        >
                          Save
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>

             

                  <div className="comment-one">
                    <h3 className="comment-one__title">
                      {singleBlog?.comments?.length} Comments
                    </h3>
                    {singleBlog?.comments?.map((comment, index) => (
                      <div className="comment-one__single" key={index}>
                        <div className="comment-one__image d-flex justify-content-center">
                          {comment?.user?.avatar ? 
                            <img src={imageUrl+"/"+comment?.user?.avatar } alt="avatar" />
                            :
                            <img src={publicUrl + "assets/images/deafult_avater/profile.png"} alt="Member" />
                        }
                        </div>
                        <div className="comment-one__content">
                          <h3>{comment?.user?.name}</h3>
                          <div
                            dangerouslySetInnerHTML={{ __html: comment.comment }}
                          ></div>
                          <span>
                            {formatDateTimeWithAMPM(comment?.created_at)}
                          </span>
                         
                          {user?.id?.toString() === comment?.user?.id?.toString() && (
                          
                            <div className=" mt-3">
                              <button
                                type="button"
                                className="btn-lg btn btn-outline-info m-2 px-4"
                                onClick={(e) =>
                                  clickEditButton(
                                    comment?.comment,
                                    comment?.id,
                                    e,
                                  )
                                }
                              >
                                Edit
                              </button>
                              {/* <button
                                type="button"
                                className="btn-lg btn btn-outline-danger"
                                onClick={(e) =>
                                  clickDeleteButton(comment?.id, e)
                                }
                              >
                                Delete
                              </button> */}
                              <button
                                type="button"
                                className="btn-lg btn btn-outline-danger"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDelete(comment?.id);
                                }}
                              >
                                Delete
                              </button>


                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5">
                <div className="sidebar">
                  <BlogQuery />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
export default SingleBlogDetails;
