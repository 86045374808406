import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getNotices } from "../../redux/actions/noticeAction";
import Pagination from "react-js-pagination";
import Loader from "../../common/Loader/Loader";

const NoticeComponent = () => {
  const alert = useAlert();
  const { notices, error, loading } = useSelector((state) => state.notices);
  const dispatch = useDispatch();
  const { data: noticesData, meta } = notices;
  const [activePage, setActivePage] = useState(meta?.current_page || 1);
  useEffect(() => {
    dispatch(getNotices(activePage));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [activePage, dispatch]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container pb-5">
          {noticesData ?
            <div>
              <div className="d-flex align-items-center justify-content-center">

                <table className="styled-table">
                  <thead>
                    <tr>
                      <th cscope="col">Date</th>
                      <th cscope="col" className="text-center">
                        Notices
                      </th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>

                  <tbody>

                    {noticesData?.map((notice,index) => (
                      <tr key={index}>
                        <td className="notice-thumbnail">
                          <p>{notice?.date}</p>
                        </td>

                        <td className="product-name">
                          <span className="notice-title">{notice?.title}</span>
                        </td>

                        <td className="pdf-link-td">
                          <Link
                            to={`/notices/${notice?.id}`}
                            className=""
                          >
                            <span className="pdf-icon ">
                              <FontAwesomeIcon icon={faFilePdf} />
                            </span>
                          </Link>
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>

              <div className="pagination-div">
                {meta?.total > meta?.per_page && (
                  <div className="pagination-div">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={meta?.per_page}
                      totalItemsCount={meta?.total || 0}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      activeLinkClass="active-pagination-button"
                    />
                  </div>
                )}
              </div>
            </div>
            :
            <p className="pt-5">Notice is Empty!!</p>}
        </div>
      )}
    </div>
  );
};

export default NoticeComponent;
