

import React from "react";
import { Link } from "react-router-dom";
import { imageUrl } from "../../utils/baseUrl";
const MemberCard = ({ member, index }) => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return (
    <div key={index}>

      <div className=" p-1  row testimonial-one__single  justify-content-between align-item-center text-center ">
        <div className="row">
          <div className="testimonial-one__img col-xl-3 member-frofile-card">

            {member?.avatar === null ?
              <img src={publicUrl + "assets/images/deafult_avater/graduate-avater.jpg"} alt="Member" />
              :
              <img src={`${imageUrl}/${member?.avatar}`} alt="Member " />

            }
          </div>


          <div className=" col-xl-4 p-1 m-2 text-start ">
            <h5 className="">{member?.name}</h5>
            <p className=" testimonial-one__client-title">
              {member?.designation ? member?.designation : "--"}
            </p>
            <p className=" testimonial-one__client-title">
              {member?.organization ? member?.organization : "--"}
            </p>


          </div>

          <div className=" col-xl-4 py-3 profile-button-div">
            <div>
              <Link to={`/members/${member?.id}`} className="thm-btn comment-form__btn full_button profile-button">
                Profile
              </Link>
            </div>

          </div>
        </div>



      </div>

    </div>
  )
}

export default MemberCard;