import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useEffect } from "react";
import { clearErrors, logout } from "../../redux/actions/userActions";
import { imageUrl } from "../../utils/baseUrl";

const Nav = () => {
  const [isShown, setIsShown] = useState(false);
  const { isAuthenticated, error, user ,message} = useSelector((state) => state.auth);

  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (message) {
      alert.success(message);
      dispatch(clearErrors());

    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, isAuthenticated, navigate]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
    // alert.success("Logged out successfully.");
    
  };
  let publicUrl = process.env.PUBLIC_URL + '/'

  return (
    <div>
      <div className="sticky main-menu text-center mr-5 pr-5">
        <nav className="d-flex ">
          <ul className="main-menu__list relevent ">
            {
              isAuthenticated &&
              <li>
                <Link to={`/members`}>Member</Link>
              </li>
            }
            <li>
              <Link to={`/events`}>Events</Link>
            </li>
            <li>
              <Link to={`/blogs`}>Blog </Link>
            </li>
            <li>
              <Link to={`/galleries`}>Gallery</Link>
            </li>

            <li>
              <Link to={`/news`}>News</Link>
            </li>
            <li>
              <Link to={`/all-alumni`}> Alumnis</Link>
            </li>
            {
              isAuthenticated && <li>
                <Link to={`/jobs`}>Jobs</Link>
              </li>
            }

            <li
              className="dropdown"
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
            >
              <Link to={`#`}>
                <span className="px-2">About us </span>
                {isShown ? (
                  <i className="fa fa-angle-up"></i>
                ) : (
                  <i className="fa fa-angle-down"></i>
                )}
              </Link>
              <ul>
                <li>
                  <Link to={`/about`}>Who We Are</Link>
                </li>
                <li>
                  <Link to={`/history`}>History</Link>
                </li>
                <li>
                  <Link to={`/rule-book`}>Rulebook </Link>
                </li>
                <li>
                  <Link to={`/committee`}>Executive Committee </Link>
                </li>
                <li>
                  <Link to={`/ec-council`}>EC Council </Link>
                </li>
                <li>
                  <Link to={`/contact`}>Contact</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to={`/notices`}>Notice</Link>
            </li>
            {isAuthenticated ? (
              <div className="">


                <li className="dropdown">
                  {user?.avatar ? (
                    <img
                      src={`${imageUrl}/${user?.avatar}`}
                      alt={user?.name}
                      className="user-pic"
                    />

                  ) : (
                    <>
                      <img src={publicUrl + "assets/images/deafult_avater/profile.png"} alt="Member" className="user-pic" />

                    </>
                  )}

                  <ul className="profile-dropdown">
                    <li>
                      <Link to={`/profile`}>Profile</Link>
                    </li>
                    <li>
                      <Link to={`/messages`}>Messages</Link>
                    </li>
                    <li>
                      <Link to={`/`} onClick={logoutHandler}>


                        Logout

                      </Link>
                    </li>
                  </ul>
                </li>
              </div>
            ) : (
              // !loading && (
              <ul className="main-menu__list relevent login-btn">
                <li>
                  <Link to={`/login`}>
                    <span> Login</span>
                  </Link>
                </li>
              </ul>
              // )
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Nav;
