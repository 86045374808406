import React, { useEffect, useState } from "react";
import FilterOne from "../filter/FilterOne";
import MemberCard from "./MemberCard";
import { useSelector, useDispatch } from "react-redux";

import Loader from "../../common/Loader/Loader";
import { useAlert } from "react-alert";
import Pagination from "react-js-pagination";
import { getMembers, clearErrors } from "../../redux/actions/membersAction";

const Member = () => {
  const alert = useAlert();
  const { members, error, loading } = useSelector((state) => state?.members);


  const dispatch = useDispatch();
  const { data: membersData, meta } = members;
  const [activePage, setActivePage] = useState(meta?.current_page || 1);

  const [searchTerm, setSearchTerm] = useState("");

  const [filters, setFilters] = useState({
    batch: [],
    department: [],
    job_field: [],
    job_organization: [],
  });

  const [formattedFilter,setFormattedFilter]=useState(null)
  const { batches, departments, jobFields, jobOrgs } = useSelector((state) => ({
    batches: state?.batch?.batches,
    departments: state?.department?.departments,
    jobFields: state?.jobFields?.jobFields,
    jobOrgs: state?.jobOrgs?.jobOrgs,
  }));
  const updatedBatches = batches?.map((batch) => ({
    ...batch,
    title: batch?.title || batch?.session,
  }));
  const handleFilterChange = (filterName, selectedValues) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: selectedValues }));
  };

  const handleApplyFilter = (e) => {
    e.preventDefault();
    const formattedFilters = {};
    Object?.entries(filters)?.forEach(([key, value]) => {
      formattedFilters[key] = value?.join(',');
    });
   setFormattedFilter(formattedFilters);
   setActivePage(1);
    dispatch(getMembers(activePage, formattedFilters));
  };

  const handleClearFilters = () => {
    setFilters({
      batch: [],
      department: [],
      job_field: [],
      job_organization: [],
    });

    dispatch(getMembers(activePage));
  };
  useEffect(() => {
    if(searchTerm!=='')
      {
        dispatch(getMembers(activePage, { search_table: searchTerm }));
      }
      else if (formattedFilter!==null)
        {
          dispatch(getMembers(activePage, formattedFilter));

        }
   else{
        dispatch(getMembers(activePage));

      }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [activePage, dispatch, formattedFilter]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setActivePage(1);
      await dispatch(getMembers(activePage, { search_table: searchTerm }));
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <section className="">
          <div className="container">
            <div className="text-center p-4">
              <h3 className="">
                Now we have <span className="text_basecolor"> {meta?.total}</span> member <hr />
              </h3>
            </div>

            <div className="row">
              <div className="col-xl-4 px-5">
                <div>
                  <FilterOne
                    filterTitle={"Batch"}
                    dataArray={updatedBatches}
                    onFilterChange={(values) => handleFilterChange("batch", values)}
                    selectedValues={updatedBatches?.filter((item) => filters?.batch?.includes(item?.id))}

                  />

                  <FilterOne
                    filterTitle={"Department"}
                    dataArray={departments}
                    onFilterChange={(values) => handleFilterChange("department", values)}
                    selectedValues={departments?.filter((item) => filters?.department?.includes(item?.id))}
                  />

                  <FilterOne
                    filterTitle={"Job Field"}
                    dataArray={jobFields}
                    onFilterChange={(values) => handleFilterChange("job_field", values)}
                    selectedValues={jobFields?.filter((item) => filters?.job_field?.includes(item?.id))}

                  />
                  <FilterOne
                    filterTitle={"Job Organizations"}
                    dataArray={jobOrgs}
                    onFilterChange={(values) => handleFilterChange("job_organization", values)}
                    selectedValues={jobOrgs?.filter((item) => filters?.job_organization?.includes(item?.id))}
                  />
                </div>
                <div className="py-4 d-flex">
                  <button
                    type="button"
                    className="thm-btn comment-form__btn mx-1  "
                    onClick={(e) => handleApplyFilter(e)}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    className="thm-btn comment-form__btn mx-1"
                    onClick={handleClearFilters}
                  >
                    Clear
                  </button>
                </div>

              </div>
              <div className="col-xl-8 px-5">
                <div className="pb-4">
                  <div className="row w-100 justify-content-end align-items-end mb-3">
                    <div className="col-xl-12 input-group search-box  w-sm-50">
                      <input
                        className="form-control px-3 searchbar"
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <button className="btn btn-outline-success" type="button" onClick={handleSearch}>
                        <i className="fa fa-search p-2 "></i>
                      </button>
                    </div>
                  </div>
                  <div>
                      {
                        membersData?.map((member, index) => (
                            <MemberCard member={member} key={index} />
                        ))
                      }


                  </div>
                  <div className="pagination-div">
                    {meta?.total > meta?.per_page && (
                      <div className="pagination-div">
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={meta?.per_page}
                          totalItemsCount={meta?.total || 0}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          activeLinkClass="active-pagination-button"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Member;