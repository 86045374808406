export const ALL_CHAT_REQUEST = 'ALL_CHAT_REQUEST'
export const ALL_CHAT_SUCCESS = 'ALL_CHAT_SUCCESS'
export const ALL_CHAT_FAIL = 'ALL_CHAT_FAIL'

export const SINGLE_CHAT_REQUEST = 'SINGLE_CHAT_REQUEST';
export const SINGLE_CHAT_SUCCESS = 'SINGLE_CHAT_SUCCESS';
export const SINGLE_CHAT_FAIL = 'SINGLE_CHAT_FAIL';

export const MESSAGE_REQUEST = 'MESSAGE_REQUEST';
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const MESSAGE_FAIL = 'MESSAGE_FAIL';

export const DELETE_MESSAGE_COMMENT_REQUEST = 'DELETE_MESSAGE_COMMENT_REQUEST';
export const DELETE_MESSAGE_COMMENT_SUCCESS = 'DELETE_MESSAGE_COMMENT_SUCCESS';
export const DELETE_MESSAGE_COMMENT_FAIL = 'DELETE_MESSAGE_COMMENT_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS'