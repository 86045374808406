import React from "react";
import { Link } from "react-router-dom";

const AlumniComponent = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div>
      <div className="container my-5">
        <div className="row justify-content-center">
        

          <div className="alumni col-xl-3 col-md-2 mx-2 ">
            <Link to="http://www.cuetiansinaustralia.org/" target="blank">
              <div className="alumni-logo ">
                <div className=" d-flex justify-content-center">
                  <img
                    src={publicUrl + "assets/images/alumni_logo/austrilia.jpg"}
                    alt=""
                  />
                </div>

                <h5 className="text-center py-1">Austrilia Alumni</h5>
                <p className="text-center py-1">Welcome to Everyone Austrilia</p>
              </div>
            </Link>
          </div>

          <div className="alumni col-xl-3 col-md-2 mx-2">
            <Link to="https://cuetalumni.de/" target="blank">
              <div className="alumni-logo ">
                <div className=" d-flex justify-content-center">
                  <img
                    src={publicUrl + "assets/images/alumni_logo/germany.png"}
                    alt="Alumni"
                  />
                </div>

                <h5 className="text-center py-1">Germany Alumni</h5>
                <p className="text-center py-1">Welcome to Everyone Germany</p>
              </div>
            </Link>
          </div>

          <div className="alumni col-xl-3 col-md-2 mx-2">
            <Link to="http://www.cuetalumni.ca/" target="blank">
              <div className="alumni-logo ">
                <div className=" d-flex justify-content-center">
                    <img
                  src={publicUrl + "assets/images/alumni_logo/cuet.png"}
                  alt=""
                />
                </div>
              
                <h5 className="text-center py-1">Canada Alumni</h5>
                <p className="text-center py-1">Welcome to Everyone Canada</p>
              </div>
            </Link>
          </div>
          <div className="alumni col-xl-3 col-md-2 mx-2">
            <Link to="http://cuetusa.com/" target="blank">
              <div className="alumni-logo ">
                <div className=" d-flex justify-content-center">
                  <img
                    src={publicUrl + "assets/images/alumni_logo/usa.jpg"}
                    alt=""
                  />
                </div>

                <h5 className="text-center py-1">USA Alumni</h5>
                <p className="text-center py-1">Welcome to Everyone USA</p>
              </div>
            </Link>
          </div>

          <div className="alumni col-xl-3 col-md-2 mx-2">
            <Link to="#" target="blank">
              <div className="alumni-logo ">
                <div className=" d-flex justify-content-center">
                  <img
                    src={publicUrl + "assets/images/alumni_logo/cuet.png"}
                    alt=""
                  />
                </div>

                <h5 className="text-center py-1">UK Alumni</h5>
                <p className="text-center py-1">Welcome to Everyone UK</p>
              </div>
            </Link>
          </div>
          <div className="alumni col-xl-3 col-md-2 mx-2">
            <Link to="#" target="blank">
              <div className="alumni-logo ">
                <div className=" d-flex justify-content-center">
                  <img
                    src={publicUrl + "assets/images/alumni_logo/cuet.png"}
                    alt=""
                  />
                </div>

                <h5 className="text-center py-1">Japan Alumni</h5>
                <p className="text-center py-1">Welcome to Everyone Japan</p>
              </div>
            </Link>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default AlumniComponent;
