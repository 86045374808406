import axios from "axios";
import {
  ALL_BLOG_REQUEST,
  ALL_BLOG_SUCCESS,
  ALL_BLOG_FAIL,
  SINGLE_BLOG_REQUEST,
  SINGLE_BLOG_SUCCESS,
  SINGLE_BLOG_FAIL,
  CLEAR_ERRORS
} from "../constants/blogConstant";
import { baseUrl } from "../../utils/baseUrl";


export const getBlogs = (pageNumber) => async (dispatch) => {
  try {
    dispatch({ type: ALL_BLOG_REQUEST });

    const data = await axios.get(`${baseUrl}/blogs?page=${pageNumber}`, { withCredentials: true });

    dispatch({
      type: ALL_BLOG_SUCCESS,
      payload: data?.data
    });

  } catch (error) {
    dispatch({
      type: ALL_BLOG_FAIL,
      payload: error?.response?.data?.message
    });
  }
};

export const getSingleBlog = (blogId) => async (dispatch) => {

  try {
    dispatch({ type: SINGLE_BLOG_REQUEST });
    const { data } = await axios.get(`${baseUrl}/blogs/${blogId}`, { withCredentials: true });
    dispatch({
      type: SINGLE_BLOG_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: SINGLE_BLOG_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS
  });
};


