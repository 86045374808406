import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";

import ProfileMemberComponent from "../components/profile/ProfileMemberComponent";

const MemberProfile = () => {
  return (
    <>
      <HeaderOne />
      <Breadcrumb heading="Member Details" currentPage="Member Details" />
      <ProfileMemberComponent />

    </>
  );
};

export default MemberProfile;
