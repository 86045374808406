import React, { useEffect, useState } from "react";
import { Link, useParams,redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getSingleMember,
} from "../../redux/actions/membersAction";
import Loader from "../../common/Loader/Loader";
import { imageUrl } from "../../utils/baseUrl";
import { Modal } from "react-bootstrap";
import { getChats, store } from "../../redux/actions/chatAction";
import { useAlert } from "react-alert";
const ProfileMemberComponent = () => {
  const { id } = useParams();
  const { error, loading, member } = useSelector((state) => state.members);
  const { user } = useSelector((state) => state?.auth);
  const [showModal, setShowModal] = useState(false);
  const [message, setmessage] = useState("");
  const { message: toastMessage } = useSelector(state => state.chats);
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleMember(id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (toastMessage)
    {
      alert.success(toastMessage);
      dispatch(clearErrors());
      }
  }, [dispatch, id]);

  const sendMessage = async () => {
    try {
      await dispatch(store(member?.user_id, message));
      // alert.success("Message Sent Successfully !! ");
      setmessage("");
      setShowModal(!showModal);
      await dispatch(getChats());
     return redirect('/messages');
      
    } catch (error) {
      console.error("Error posting Edit comment:", error);
    }
  };
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container py-5">
          <div className="main-body user-profile-card">
            <div className="row">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      {member?.avatar ? (
                        <img
                          src={imageUrl + "/" + member?.avatar}
                          alt="Member "
                          className=" p-1 user-profile-image"
                        />
                      ) : (
                        <img
                          src={
                            publicUrl +
                            "assets/images/deafult_avater/graduate-avater.jpg"
                          }
                          alt="Member"
                          className=" p-1 user-profile-image"
                        />
                      )}
                      <div className="mt-3">
                        <h4>{member?.name}</h4>
                        <p className="text-secondary mb-1">
                          {member?.designation ? member?.designation : "--"}
                        </p>
                        <p className="text-muted font-size-sm">
                          {member?.organization ? member?.organization : "--"}
                        </p>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <div className="team-one__social pb-4">
                      {member?.social_links ? (
                        <>
                          <Link
                            to={
                              member?.social_links[0]?.facebook
                                ? member?.social_links[0]?.facebook
                                : "#"
                            }
                            target="blank"
                          >
                            <i className="fab fa-facebook"></i>
                          </Link>

                          <Link
                            to={
                              member?.social_links[0]?.linkedin
                                ? member?.social_links[0]?.linkedin
                                : "#"
                            }
                            target="blank"
                          >
                            <i className="fab fa-linkedin"></i>
                          </Link>
                          <Link
                            to={
                              member?.social_links[0]?.personal_website
                                ? member?.social_links[0]?.personal_website
                                : "#"
                            }
                            target="blank"
                          >
                            <i className="fas fa-globe"></i>
                          </Link>

                          <Link
                            to={
                              member?.social_links[0]?.youtube
                                ? member?.social_links[0]?.youtube
                                : "#"
                            }
                            target="blank"
                          >
                            <i className="fab fa-youtube"></i>
                          </Link>
                          <Link to={`mailto:${member?.email}`} target="blank">
                            <i className="far fa-envelope"></i>
                          </Link>
                          {user?.id !== member?.user_id && (
                            <Link
                              to={"#"}
                              onClick={() => setShowModal(!showModal)}
                            >
                              <div>
                                {" "}
                                <i className="fab fa-facebook-messenger"></i>{" "}
                              </div>
                            </Link>
                          )}
                        </>
                      ) : (
                        "--"
                      )}
                    </div>
                  </div>
                </div>
                <div className="editmodal">
                  <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="comment-form__input-box comment-form__textarea">
                        <textarea
                          name="message"
                          placeholder="Type your message"
                          value={message}
                          onChange={(e) => setmessage(e?.target?.value)}
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                          className="thm-btn comment-form__btn full_button profile-button"
                          onClick={sendMessage}
                      >
                        Send
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>

              <div className="col-md-8">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Full Name</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        {member?.name ? member?.name : "--"}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Email</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        {member?.email ? member?.email : "--"}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Phone</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        {member?.phone ? member?.phone : "--"}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Department</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        {member?.department ? member?.department : "--"}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Batch</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        {member?.batch ? member?.batch : "--"}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Address</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        {member?.address ? member?.address : "--"}
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Job History</h6>
                    </div>
                    <hr />
                    {member?.job_histories
                      ? member?.job_histories?.map((jobHistory, index) => (
                          <div className="row" key={index}>
                            <div className="col-sm-3">
                              <h6 className="mb-0">
                                {jobHistory?.job_organization_title}
                              </h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              <div>
                                <p> - {jobHistory?.position_title} </p>
                                <p>
                                  ( {jobHistory?.start_date} to{" "}
                                  {jobHistory?.end_date})
                                </p>
                                <p>
                                  {" "}
                                  - {jobHistory?.start_date} to{" "}
                                  {jobHistory?.description}
                                </p>
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))
                      : "--"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileMemberComponent;
