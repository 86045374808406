import React, { useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { imageUrl } from '../../utils/baseUrl';
import { useAlert } from 'react-alert';
import { clearErrors, getSingleNotice } from '../../redux/actions/noticeAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../common/Loader/Loader';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const NoticeViewComponent = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const alert = useAlert();
  const { singleNotice, error, loading } = useSelector((state) => state.notices);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSingleNotice(id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [ dispatch, id]);

  return (
    <div className='container'>
      {loading ? (
        <div className="text-center mt-5"><Loader /></div>
      ) : (
        <div className='d-flex justify-content-center'>
          {singleNotice ?
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer
                fileUrl={`${imageUrl}/${singleNotice?.pdf_url}`}
                plugins={[defaultLayoutPluginInstance]}
                defaultScale={1.5}
              />
            </Worker>
            :
            <p className='pt-5 mt-5'>Pdf Does not work properly!!</p>
          }

        </div>
      )}
    </div>
  )
}

export default NoticeViewComponent;
