import React, { useEffect } from "react";
import { clearErrors } from "../../redux/actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import Loader from "../../common/Loader/Loader";

const ContactInfo = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { settings, error, loading } = useSelector((state) => state.settings);
  const address = settings?.data[6]?.value;
  const email = settings?.data[5]?.value;
  const phone = settings?.data[1]?.value;

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [ dispatch]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <section className="contact-info">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4">
                <div className="contact-info__single">
                  <div className="contact-icon">
                    <span className="icon-place"></span>
                    <h4 className="contact-info__title">Address</h4>
                  </div>
                  {address ?
                    <p className="contact-info__text" dangerouslySetInnerHTML={{ __html: address }} ></p>

                    :
                    <p className="text-white" > -- </p>
                  }
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div className="contact-info__single">
                  <div className=" contact-icon ">
                    <span className="icon-call "></span>
                    <h4 className="contact-info__title ">Contact</h4>
                  </div>
                  {phone ?
                    <p className="contact-info__text" dangerouslySetInnerHTML={{ __html: phone }} ></p>

                    :
                    <p className="text-white" > -- </p>
                  }
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div className="contact-info__single">
                  <div className="contact-icon">
                    <span className="icon-envelope"></span>
                    <h4 className="contact-info__title">Mail</h4>
                  </div>
                  {email ?
                    <p className="contact-info__text" dangerouslySetInnerHTML={{ __html: email }} ></p>

                    :
                    <p className="text-white" > -- </p>
                  }
                  <p className="contact-info__email-phone"></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
export default ContactInfo;
