import {
  ALL_NOTICE_REQUEST,
  ALL_NOTICE_SUCCESS,
  ALL_NOTICE_FAIL,
  SINGLE_NOTICE_REQUEST,
  SINGLE_NOTICE_SUCCESS,
  SINGLE_NOTICE_FAIL,
  CLEAR_ERRORS
} from "../constants/noticeConstants";
const initialState = {
  notices: [],
  singleNotice: null,
  loading: false,
  error: null
};

const noticeReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ALL_NOTICE_REQUEST:
    case SINGLE_NOTICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ALL_NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        notices: action?.payload
      };

    case SINGLE_NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleNotice: action?.payload
      };

    case ALL_NOTICE_FAIL:
    case SINGLE_NOTICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};

export default noticeReducer;
