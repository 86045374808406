import React, { useState } from 'react';
import * as AiIcons from 'react-icons/ai';
import { AiOutlineBars } from 'react-icons/ai';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';
import styled from 'styled-components';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import {  logout } from "../../redux/actions/userActions";

const menuData = [


    {
        title: 'Home',
        path: '/',

    },
    {
        title: 'Members',
        path: '/members',
    },
    {
        title: 'Events',
        path: '/events',
    },

    {
        title: 'Blogs',
        path: '/blogs',
    },

    {
        title: "Gallery",
        path: '/galleries',
    },
    {
        title: 'News',
        path: '/news',
    },

    {
        title: "Alumnies",
        path: '/all-alumni',
    },
    {
        title: 'Jobs',
        path: '/jobs',
    },



    {
        title: 'About us',
        path: '#',

        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Who We Are',
                path: '/about',

            },
            {
                title: 'History',
                path: '/history',

            },
            {
                title: 'Rulebook',
                path: '/rule-book',
            },
            {
                title: 'Executive Committee',
                path: '/committee',
            },
            {
                title: 'EC Council',
                path: '/ec-council',
            },

        ],
    },


    {
        title: 'Contact',
        path: '/contact',
    },
];

const deshBordData = [

    {
        title: 'Deshbord',
        path: '#',

        iconClosed: <CgChevronLeft />,
        iconOpened: <CgChevronDown />,

        subNav: [
            {
                title: 'Profile',
                path: '/profile',

            }

        ],
    },




];

const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    &:hover {
        background: #B6843D;
        color: #fff;
        cursor: pointer;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
    color: #fff;
    font-weight: 400;
`;

const DropdownLink = styled(Link)`
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    &:hover,
    &:active {
        background: #B6843D;
        cursor: pointer;
        color: #fff;
    }
`;

const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    return (
        <>
            <SidebarLink to={process.env.PUBLIC_URL + `${item?.path}`} onClick={item?.subNav && showSubnav}>
                <div>
                    {item?.icon}
                    <SidebarLabel>{item?.title}</SidebarLabel>
                </div>
                <div>
                    {item?.subNav && subnav ? item?.iconOpened : item?.subNav ? item?.iconClosed : null}
                </div>
            </SidebarLink>
            {subnav &&
                item?.subNav?.map((data, index) => (
                    <DropdownLink to={process.env.PUBLIC_URL + `${data?.path}`} key={index}>
                        {item?.icon}
                        <SidebarLabel>{data?.title}</SidebarLabel>
                    </DropdownLink>
                ))}
        </>
    );
};

const NavIcon = styled(Link)`
    color: #B6843D;
    font-size: 2rem;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 20px;
`;

const SidebarNav = styled.nav`
    background-color: #001d23;
    width: 300px;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
    top: 0;
    right: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 99999;
`;

const SidebarWrap = styled.div`
    width: 100%;
`;

const MobileMenu = () => {
    const [sidebar, setSidebar] = useState(false);
    const { isAuthenticated, user } = useSelector((state) => state.auth);

    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logoutHandler = () => {
        dispatch(logout());
        alert.success("Logged out successfully.");
        navigate("/");
    };

    const showSidebar = () => setSidebar(!sidebar);
    let publicUrl = process.env.PUBLIC_URL + '/'

    return (
        <>
            <div className='mobilemenu'>
                <NavIcon to="#" style={{ justifyContent: 'flex-end' }}>
                    <AiOutlineBars onClick={showSidebar} />
                </NavIcon>

                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                        <div className="mobile-nav__content">
                            
                            {isAuthenticated &&
                            <>
                            {user?.avatar ? (
                                <img
                                    src={user?.avatar}
                                    alt={user.name}
                                    className="user-pic"
                                />

                            ) : (
                                <>
                                    <img src={publicUrl + "assets/images/deafult_avater/profile.png"} alt="Member" className="user-pic" />

                                </>
                                )}
                            </>
                            }

                            <NavIcon to="#">
                                <AiIcons.AiOutlineClose
                                    style={{
                                        color: 'white',
                                        fontSize: '18px',
                                        justifyContent: 'flex-start',
                                    }}
                                    onClick={showSidebar}
                                />
                            </NavIcon>
                            {isAuthenticated ?
                                <div>
                                    <div>
                                        {deshBordData.map((item, index) => (
                                            <SubMenu item={item} key={index} />
                                        ))}
                                    </div>

                                    <div onClick={logoutHandler}>
                                        <SubMenu item={{
                                            title: 'Logout',
                                            path: '#',

                                        }} key="login" />
                                    </div>

                                </div>
                                :
                                <div>
                                    <SubMenu item={{
                                        title: 'Login',
                                        path: '/login',

                                    }} key="login" />

                                </div>
                            }

                            {menuData.map((item, index) => (
                                <SubMenu item={item} key={index} />
                            ))}


                        </div>
                    </SidebarWrap>
                </SidebarNav>
            </div>
        </>
    );
};

export default MobileMenu;