import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Nav from './Nav';
import MobileMenu from './MobileMenu';

import { clearErrors } from "../../redux/actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { imageUrl } from '../../utils/baseUrl';


const HeaderOne = () => {


    const dispatch = useDispatch();
    const alert = useAlert();
    const { settings, error } = useSelector((state) => state.settings);
    const address = settings?.data[6]?.value;
    const email = settings?.data[5]?.value;
    const phone = settings?.data[1]?.value;
    const logo = settings?.data[3]?.value;

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
    }, [ dispatch]);

    return (
        <>
            <header className="main-header clearfix">
                <div className="main-header__top clearfix">
                    <div className="container clearfix">
                        <div className="main-header__top-inner clearfix">
                            <div className="main-header__top-left">
                                <ul className="list-unstyled main-header__top-address">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-call"></span>
                                        </div>
                                        <div className=" text text-white">
                                            <div
                                                dangerouslySetInnerHTML={{ __html: phone }}
                                            ></div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="main-header__top-right">
                                <ul className="list-unstyled main-header__top-right-content text-white">

                                    <li className='d-flex'>
                                        <div className="icon px-3">
                                            <span className="icon-envelope"></span>
                                        </div>
                                        <div className="text">
                                            <p
                                                dangerouslySetInnerHTML={{ __html: email }}
                                            ></p>
                                        </div>
                                    </li>
                                    <li className='d-flex'>
                                        <div className="icon px-3">
                                            <span className="icon-place"></span>
                                        </div>
                                        <div className="text">
                                            <p
                                                dangerouslySetInnerHTML={{ __html: address?.slice(82) }}
                                            ></p>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="main-menu ">
                    <div className="container clearfix">
                        <div className="main-menu-wrapper clearfix">
                            <div className="main-menu-wrapper__left">
                                <div className="main-menu-wrapper__logo">
                                    <Link to={process.env.PUBLIC_URL + `/`}>
                                        {logo &&
                                            <img src={`${imageUrl}/${logo}`} alt="alumni_logo" />

                                        }
                                    </Link>
                                </div>
                            </div>
                            <div className="container">
                                <div className="main-menu-wrapper__right">
                                    <div className="main-menu-wrapper__main-menu d-flex ">
                                        <MobileMenu />
                                        <Nav />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default HeaderOne;
