import axios from 'axios'
import apiClient from '../../utils/api'
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    NEW_PASSWORD_REQUEST,
    NEW_PASSWORD_SUCCESS,
    NEW_PASSWORD_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    CLEAR_ERRORS,

} from '../constants/userConstants'
import { baseUrl } from '../../utils/baseUrl'
// Login

const csrf = () => apiClient.get('/sanctum/csrf-cookie')


export const login = (email, password) => async (dispatch) => {
    try {

        await csrf();

        dispatch({ type: LOGIN_REQUEST })

        // const config = {
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     withCredentials: true

        // }

        const { data } = await apiClient.post(`/api/v1/auth/login`, {
            email,
            password
        })

        window.localStorage.setItem('token', data?.access_token);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data?.user
        })



    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error?.response?.data?.message
        })
    }
}

// Register user
export const register = (userData) => async (dispatch) => {
    try {

        await csrf();

        dispatch({ type: REGISTER_USER_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        const { data } = await axios.post(`${baseUrl}/auth/signup`, userData, config)

        dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: data?.message
        })
        

    } catch (error) {
        dispatch({
            type: REGISTER_USER_FAIL,
            payload: error?.response?.data

        })
    }
}

// Load user
export const loadUser = () => async (dispatch) => {
    try {
        await csrf();


        dispatch({ type: LOAD_USER_REQUEST })

        const { data } = await apiClient.get(`${baseUrl}/auth/user`)
        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: data?.user
        })

    } catch (error) {
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error?.response?.data?.message

        })
    }
}

export const updateProfileImage = (formData) => async (dispatch) => {
    try {
        await csrf();

        dispatch({ type: UPDATE_PROFILE_REQUEST })
     
            const config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': `Bearer ${localStorage?.getItem('token')}`,
                },
                withCredentials: false,
                withXSRFToken: true,
            };
            const { data } = await axios.post(`${baseUrl}/update-profile-image`, formData, config);


        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: data?.data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: error?.response?.data?.message

        })
    }
}


// Update profile
export const updateProfile = (userData) => async (dispatch) => {
    try {
        await csrf();

        dispatch({ type: UPDATE_PROFILE_REQUEST })

        const { data } = await apiClient.put(`${baseUrl}/auth/update-profile`, userData)

        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: data?.data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: error?.response?.data?.message

        })
    }
}

// Update password
export const updatePassword = (passwords) => async (dispatch) => {
    try {

        await csrf();
        dispatch({ type: UPDATE_PASSWORD_REQUEST })


        const { data } = await apiClient.put(`${baseUrl}/auth/change-password`, passwords)
        dispatch({
            type: UPDATE_PASSWORD_SUCCESS,
            payload: data?.message
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: error?.response?.data?.message

        })
        return error?.response?.data?.message;
    }
}

// Forgot password
export const forgotPassword = (email) => async (dispatch) => {
    try {

        await csrf();

        dispatch({ type: FORGOT_PASSWORD_REQUEST })

        const config = {
            headers: {
                'Accept': 'application/json',
            },
            withCredentials: true,
            withXSRFToken: true

        }

        const { data } = await axios.post(`${baseUrl}/auth/password/forget-request`, { email }, config)

        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: data?.message

        })

    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_FAIL,
            payload: error?.response?.data?.message

        })
    }
}

// Reset password
export const resetPassword = (token, passwords) => async (dispatch) => {
    try {

        dispatch({ type: NEW_PASSWORD_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`${baseUrl}/password/reset/${token}`, passwords, config)

        dispatch({
            type: NEW_PASSWORD_SUCCESS,
            payload: data?.success
        })

    } catch (error) {
        dispatch({
            type: NEW_PASSWORD_FAIL,
            payload: error?.response?.data?.message

        })
    }
}

// Logout user
export const logout = () => async (dispatch) => {
    try {

        await csrf();
        window.localStorage.removeItem('token');

        const { data } =  await apiClient.get(`${baseUrl}/auth/logout`)

        dispatch({
            type: LOGOUT_SUCCESS,
            payload:data?.message
        })

    } catch (error) {
        dispatch({
            type: LOGOUT_FAIL,
            payload: error?.response?.data?.message

        })
    }
}



// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}