import axios from "axios";
import {
  ALL_GALLERY_REQUEST,
  ALL_GALLERY_SUCCESS,
  ALL_GALLERY_FAIL,
  SINGLE_GALLERY_REQUEST,
  SINGLE_GALLERY_SUCCESS,
  SINGLE_GALLERY_FAIL,
  CLEAR_GALLERY_ERRORS
} from "../constants/galleryConstants";
import { baseUrl } from "../../utils/baseUrl";

export const getGalleries = (pageNumber) => async (dispatch) => {
  try {
    dispatch({ type: ALL_GALLERY_REQUEST });

    const data = await axios.get(`${baseUrl}/galleries?page=${pageNumber}`, { withCredentials: true });

    dispatch({
      type: ALL_GALLERY_SUCCESS,
      payload: data?.data
    });

  } catch (error) {
    dispatch({
      type: ALL_GALLERY_FAIL,
      payload: error?.response?.data?.message
    });
  }
};


export const getSingleGallery = (galleryId) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_GALLERY_REQUEST });
    const { data } = await axios.get(`${baseUrl}/galleries/${galleryId}`, { withCredentials: true });
    dispatch({
      type: SINGLE_GALLERY_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: SINGLE_GALLERY_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GALLERY_ERRORS
  });
};
