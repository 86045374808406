import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {  getLatestNews } from '../../redux/actions/newsAction';
import { useDispatch, useSelector } from 'react-redux';

const LatestNews = () => {


  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

 
  const {  latestNews } = useSelector(state => state.news);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLatestNews());
  }, [dispatch]);

  return (
    <>
      <section className="blog-three">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Latest news</h2>
          </div>
          <Slider {...sliderSettings}>
         
                      {latestNews?.data?.map((news,index) => (
                        <div key={index} className="col-xl-4 col-lg-4 col-md-4 wow fadeInUp px-2" data-wow-delay="100ms">
                          <div className="blog-three__single">
                            <div className="blog-three__img">
                              <img src={news?.image} alt={news?.title } />
                            </div>
                            <div className="blog-three__content">
                              <ul className="list-unstyled blog-three__meta">
                                <li><Link to={`/news/${news?.id}`} ><i className="far fa-calendar"></i>{news?.date}</Link></li>
                              </ul>
                              <h3 className="blog-three__title"><Link to={`/news/${news?.id}`} >{news?.title}</Link></h3>

                            </div>
                          </div>
                        </div>
                      ))}
                
          </Slider>
        </div>
      </section>
    </>
  );
};

export default LatestNews;
