import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_RESET,
  UPDATE_PROFILE_FAIL,
  SINGLE_USER_DETAILS_REQUEST,
  SINGLE_USER_DETAILS_SUCCESS,
  SINGLE_USER_DETAILS_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_RESET,
  UPDATE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_RESET,
  DELETE_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERRORS
} from '../constants/userConstants'

export const authReducer = (state = { user: {}, singleUser: {} }, action) => {
  switch (action?.type) {

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action?.payload
      }

    case LOGIN_REQUEST:
    case REGISTER_USER_REQUEST:
    case LOAD_USER_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
      }


    case LOGIN_SUCCESS:
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action?.payload,
      }


    case LOGOUT_SUCCESS:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        message: action?.payload
      }

    case LOAD_USER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        // error: action?.payload
      }

    case LOGOUT_FAIL:
      return {
        ...state,
        error: action?.payload,
        loading: false,
        isAuthenticated: false,
        user:null,
        message:"You have been logged out successfully"
      }
    case UPDATE_PROFILE_REQUEST:
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action?.payload,
      }

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action?.payload,
        message:"Profile updated successfully"
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action?.payload
      }

    case UPDATE_PROFILE_RESET:
    case UPDATE_PASSWORD_RESET:
    case UPDATE_USER_RESET:
      return {
        ...state,
        isUpdated: false
      }

    case DELETE_USER_RESET:
      return {
        ...state,
        isDeleted: false
      }

    case UPDATE_PROFILE_FAIL:
    case UPDATE_PASSWORD_FAIL:
    case UPDATE_USER_FAIL:
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action?.payload
      }
      case REGISTER_USER_FAIL:
        let error = action?.payload?.message || "An unknown error occurred.";
        if (action?.payload?.errors && typeof action.payload.errors === 'object') {
          error = Object.entries(action.payload.errors)
            .map(([field, messages]) => `${field}: ${messages.join(' ')}`)
            .join(' ');
        }
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          user: null,
          error
        };
      

    case CLEAR_ERRORS:

      return {
        ...state,
        error: null,
        message:null
      }

    default:
      return state
  }
}



export const forgotPasswordReducer = (state = {}, action) => {
  switch (action?.type) {

    case FORGOT_PASSWORD_REQUEST:
    case NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action?.payload
      }

    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        success: action?.payload
      }

    case FORGOT_PASSWORD_FAIL:
    case NEW_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        message: null

      }

    default:
      return state;
  }
}


