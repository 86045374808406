import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../redux/actions/userActions";
import { clearErrors } from "../../redux/actions/userActions";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { error, message } = useSelector((state) => state.forgotPassword);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(email==='')
      {
        alert.error('Must provide a valid email');
        return;
      }

    if (!email) {
      setErrorMessage("Please enter your email address.");
    } else {
      setErrorMessage("");
      try {
        dispatch(forgotPassword(email));

      } catch (error) {
        console.error("Error occurred during Forgot Password.", error);
        setErrorMessage(
          "An error occurred during Forgot Password. Please try again later.",
        );
      }
    }
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (message) {
      alert.success(message);
      dispatch(clearErrors());
    }
  }, [dispatch])

  return (
    <>
      <section className="p-5 mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6">
              <div className="contact-page__left">
                <div className="section-title text-left">
                  <h2 className="section-title__title text-center">
                    Forgot your password?{" "}
                  </h2>
                </div>
                <div className="contact-page__form mb-5 pb-5">
                
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="comment-form__input-box">
                          <input
                            type="email"
                            placeholder="Email address"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                          />
                        </div>
                      </div>

                      <div className="col-xl-12 pb-4">
                        {errorMessage && (
                          <div className="error-message text-danger">
                            {errorMessage}
                          </div>
                        )}
                      </div>

                      <div className="col-xl-12">
                        <div className="comment-form__btn-box d-flex justify-content-center">
                          <button
                            type="submit"
                            className="thm-btn comment-form__btn full_button"
                            onClick={(e) => handleSubmit(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>

                      <div className="col-xl-12 pb-5 pt-4 mb-5">
                        <div className="row justify-content-center pb-5 mb-5">
                          <div className="col-xl-6 d-flex justify-content-center">
                            <p>
                              Back to{" "}
                              <Link
                                to="/login"
                                className="text-success py-5 mb-5 text-center"
                              >
                                Login
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
