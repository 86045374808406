export const ALL_EVENT_REQUEST = 'ALL_EVENT_REQUEST'
export const ALL_EVENT_SUCCESS = 'ALL_EVENT_SUCCESS'
export const ALL_EVENT_FAIL = 'ALL_EVENT_FAIL'

export const SINGLE_EVENT_REQUEST = 'SINGLE_EVENT_REQUEST';
export const SINGLE_EVENT_SUCCESS = 'SINGLE_EVENT_SUCCESS';
export const SINGLE_EVENT_FAIL = 'SINGLE_EVENT_FAIL';

export const PAST_EVENT_REQUEST = 'PAST_EVENT_REQUEST';
export const PAST_EVENT_SUCCESS = 'PAST_EVENT_SUCCESS';
export const PAST_EVENT_FAIL = 'PAST_EVENT_FAIL';

export const UPCOMING_EVENT_REQUEST = 'UPCOMING_EVENT_REQUEST';
export const UPCOMING_EVENT_SUCCESS = 'UPCOMING_EVENT_SUCCESS';
export const UPCOMING_EVENT_FAIL = 'UPCOMING_EVENT_FAIL';


export const CLEAR_ERRORS = 'CLEAR_ERRORS'