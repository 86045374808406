import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterTwo from '../common/footer/FooterTwo';
import EcCouncilComponent from '../components/ec-council/EcCouncilComponent';
const EcCouncil = () => {
  return (
    <>
      <HeaderOne />
      <Breadcrumb
        heading="EC Council"
        currentPage="EC Council"
      />
      <EcCouncilComponent />

      <FooterTwo />
    </>
  )
}

export default EcCouncil