import axios from "axios";
import {
  ALL_NOTICE_REQUEST,
  ALL_NOTICE_SUCCESS,
  ALL_NOTICE_FAIL,
  SINGLE_NOTICE_REQUEST,
  SINGLE_NOTICE_SUCCESS,
  SINGLE_NOTICE_FAIL,
  CLEAR_ERRORS
} from "../constants/noticeConstants";
import { baseUrl } from "../../utils/baseUrl";

export const getNotices = (pageNumber) => async (dispatch) => {
  try {
    dispatch({ type: ALL_NOTICE_REQUEST });

    const data = await axios.get(`${baseUrl}/notices?page=${pageNumber}`, { withCredentials: true });

    dispatch({
      type: ALL_NOTICE_SUCCESS,
      payload: data?.data
    });

  } catch (error) {
    dispatch({
      type: ALL_NOTICE_FAIL,
      payload: error?.response?.data?.message
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS
  });
};

export const getSingleNotice = (noticeId) => async (dispatch) => {

  try {
    dispatch({ type: SINGLE_NOTICE_REQUEST });
    const { data } = await axios.get(`${baseUrl}/notices/${noticeId}`, { withCredentials: true });
    dispatch({
      type: SINGLE_NOTICE_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    dispatch({
      type: SINGLE_NOTICE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
