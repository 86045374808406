import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Verification = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => { 
    if (countdown === 0) {
      navigate('/login');
    }
  }, [countdown, navigate]);

  return (
    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <p style={{textAlign:'center'}}>Email verified successfully. Redirecting to login page in {countdown} seconds...</p>
    </div>
  );
};

export default Verification;
