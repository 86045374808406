import {
  ALL_CHAT_REQUEST,
  ALL_CHAT_SUCCESS,
  ALL_CHAT_FAIL,
  SINGLE_CHAT_REQUEST,
  SINGLE_CHAT_SUCCESS,
  SINGLE_CHAT_FAIL,
  CLEAR_ERRORS,
  MESSAGE_REQUEST,
  MESSAGE_SUCCESS,
  MESSAGE_FAIL,
  DELETE_MESSAGE_COMMENT_FAIL,
  DELETE_MESSAGE_COMMENT_REQUEST,
  DELETE_MESSAGE_COMMENT_SUCCESS
} from '../constants/chatConstant';


export const chatReducer = (state = { chats: [], singleChat: [] }, action) => {
  switch (action?.type) {
    case ALL_CHAT_REQUEST:
    case MESSAGE_REQUEST:
    case DELETE_MESSAGE_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        chats: action?.payload,
      };
    case MESSAGE_SUCCESS:

      return {
        ...state,
        loading: false,
        message:"Message Sent Successfully",
        singleChat: [...state.singleChat, action?.payload],
      };

    case ALL_CHAT_FAIL:
    case MESSAGE_FAIL:
    case DELETE_MESSAGE_COMMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case DELETE_MESSAGE_COMMENT_SUCCESS:
      // Filter out the deleted comment from the state
      const filteredmessages = state?.singleChat?.filter(
        (sc) => sc?._id !== action?.payload
      );

      return {
        ...state,
        loading: false,
        error: null,
        singleChat: filteredmessages,
      };

    case SINGLE_CHAT_REQUEST:
      return {
        ...state,
        loading: true,
        singleChat: null,
      };

    case SINGLE_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleChat: action?.payload,
      };

    case SINGLE_CHAT_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        message:null,
      };

    default:
      return state;
  }
}