import {

  CLEAR_ERRORS,
  ALL_JOB_POST_REQUEST,
  ALL_JOB_POST_SUCCESS,
  ALL_JOB_POST_FAIL,
  SINGLE_JOB_POST_REQUEST,
  SINGLE_JOB_POST_SUCCESS,
  SINGLE_JOB_POST_FAIL,
} from "../constants/jobPostConstant";
const initialState = {
  allJobPosts: [],
  singleJobPost: null,
  error: null,
  loading: false,
};

export const jobPostReducer = (state = initialState, action) => {
  switch (action?.type) {

    case ALL_JOB_POST_REQUEST:
    case SINGLE_JOB_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_JOB_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allJobPosts: action?.payload,
      };

    case SINGLE_JOB_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleJobPost: action?.payload,
      };

    case ALL_JOB_POST_FAIL:
    case SINGLE_JOB_POST_FAIL:
      return { ...state, loading: false, error: action?.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null, message: null };

    default:
      return state;
  }
};
