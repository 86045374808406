'use client'
import React, { useEffect } from 'react';
import './Loader.css';

const Loader = () => {
  useEffect(() => {
    const preloader = document.querySelector('.preloader');

    if (preloader) {
      preloader.style.transition = 'opacity 0.3s'; 
      preloader.style.opacity = '1';
      setTimeout(() => {
        preloader.style.opacity = '0';
        setTimeout(() => {
          preloader.style.display = 'none';
        }, 300); 
      }, 800);
    }
  }, []);

  return (
    <div className="preloader">
      <div className="preloader__circle-loader"></div>
      <div className="preloader__logo">
        <img src="/assets/images/logo.png" alt="Preload" />
      </div>
    </div>
  );
};

export default Loader;
