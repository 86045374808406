import axios from 'axios';
import {
  ALL_NEWS_REQUEST,
  ALL_NEWS_SUCCESS,
  ALL_NEWS_FAIL,
  SINGLE_NEWS_REQUEST,
  SINGLE_NEWS_SUCCESS,
  SINGLE_NEWS_FAIL,
  LATEST_NEWS_REQUEST,
  LATEST_NEWS_SUCCESS,
  LATEST_NEWS_FAIL,
  CLEAR_ERRORS,
} from '../constants/newsConstants.js';
import { baseUrl } from '../../utils/baseUrl.js';

export const getNews = (pageNumber) => async (dispatch) => {
  try {
    dispatch({ type: ALL_NEWS_REQUEST });

    const { data } = await axios.get(`${baseUrl}/news?page=${pageNumber}`);
    dispatch({ type: ALL_NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ALL_NEWS_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

export const getSingleNews = (newsId) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_NEWS_REQUEST });

    const { data } = await axios.get(`${baseUrl}/news/${newsId}`);
    dispatch({ type: SINGLE_NEWS_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({
      type: SINGLE_NEWS_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

export const getLatestNews = () => async (dispatch) => {
  try {
    dispatch({ type: LATEST_NEWS_REQUEST });

    const { data } = await axios.get(`${baseUrl}/news/latest-news`);

    dispatch({ type: LATEST_NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LATEST_NEWS_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
