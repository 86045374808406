export const ALL_NEWS_REQUEST = 'GET_ALL_NEWS_REQUEST';
export const ALL_NEWS_SUCCESS = 'GET_ALL_NEWS_SUCCESS';
export const ALL_NEWS_FAIL = 'GET_ALL_NEWS_FAIL';

export const SINGLE_NEWS_REQUEST = 'SINGLE_NEWS_REQUEST';
export const SINGLE_NEWS_SUCCESS = 'SINGLE_NEWS_SUCCESS';
export const SINGLE_NEWS_FAIL = 'SINGLE_NEWS_FAIL';

export const LATEST_NEWS_REQUEST = 'LATEST_NEWS_REQUEST';
export const LATEST_NEWS_SUCCESS = 'LATEST_NEWS_SUCCESS';
export const LATEST_NEWS_FAIL = 'LATEST_NEWS_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
