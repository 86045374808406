import React from 'react'
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import MessageComponent from '../common/message/MessageComponent';
const Message = () => {
  return (
    <>
      <HeaderOne />
      <MessageComponent/>
      <FooterTwo />
    </>
  );
}

export default Message