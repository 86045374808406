import {
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  SINGLE_MEMBER_REQUEST,
  SINGLE_MEMBER_SUCCESS,
  SINGLE_MEMBER_FAIL,
  CLEAR_ERRORS,
} from '../constants/membersConstant';

const initialState = {
  members: [],
  member: null,
  loading: false,
  error: null,
};

export const membersReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_MEMBERS_REQUEST:
    case SINGLE_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        members: action?.payload,
        error: null,
      };

    case SINGLE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        member: action?.payload,
        error: null,
      };

    case GET_MEMBERS_FAIL:
    case SINGLE_MEMBER_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default membersReducer;