import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getcommittee, clearErrors } from "../../redux/actions/committeeAction";
import Loader from "../../common/Loader/Loader";
import { imageUrl } from "../../utils/baseUrl";

const CommitteeOne = () => {
  const { committeeMember, error, loading } = useSelector(
    (state) => state.committee,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getcommittee());

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch]);

  let publicUrl = process.env.PUBLIC_URL + '/'


 
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <section className="team-one">
          <div className="container">
            <div className="row">
              {committeeMember ?
                committeeMember?.map((member,index) => (
                  <div
                    className="col-xl-3 col-lg-3 col-md-4 wow fadeInLeft"
                    data-wow-delay="100ms"
                    key={index}
                  >
                    {/* Team One Single */}
                    <div className="team-one__single">
                      <div className="team-one__img">

                        {member?.avatar === null ?
                         
                          <img src={publicUrl + "assets/images/deafult_avater/graduate-avater.jpg"} alt={member?.user_name} />
                          :
                          <img src={imageUrl+ '/'+member?.avatar} alt={member?.user_name} />

                        }
                      </div>
                      <div className="team-one__content">
                        <h4 className="team-one__name">
                          <Link to={`/members/${member?.id}`}>
                            {member?.user_name}
                          </Link>
                        </h4>
                        <p className="team-one__title">{member?.designation}</p>
                        <p className="team-one__title">
                          <b>
                            {member?.start_date} - {member?.end_date}
                          </b>
                        </p>
                        <div className="team-one__social">

                          <Link
                            to='#'
                            onClick={(e) => {
                              window.location.href = member?.email;
                              e.preventDefault();
                            }}
                          >
                            <i className="far fa-envelope"></i>
                          </Link>

                          <Link to={member?.linkedIn} target="blank">

                            <i className="fab fa-linkedin"></i>
                          </Link>

                          <Link to={member?.facebook} target="blank">
                            <i className="fab fa-facebook"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                :
                <p>Committee List is Empty!!</p>}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default CommitteeOne;
