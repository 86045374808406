import Axios from "axios";
import { imageUrl } from "./baseUrl";
const apiClient = Axios.create({
  baseURL: imageUrl,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token'),

  },
  withCredentials: true,
  withXSRFToken: true

});

export default apiClient;