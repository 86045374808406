import {
  faBrain,
  faBriefcase,
  faCalendarDays,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";

const CounterOne = () => {
  const { settings } = useSelector((state) => state.settings);

  const alumni_count=settings?.data[11]?.value;
  const student_count=settings?.data[12]?.value;
  const jobs_count=settings?.data[13]?.value;
  const events_count=settings?.data[14]?.value;

  return (

    <>
      <section className="counter-one">
        <div
          className="counter-one-bg jarallax"
          data-jarallax
          data-speed="0.2"
          data-imgPosition="50% 0%"
          style={{
            backgroundColor: "white",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft"
              data-wow-delay="100ms"
              key="alumni"
            >
              <div className="counter-one__single ">
                <div className="counter-one__content ">
                  <div className="counter_icon text-white">
                    <FontAwesomeIcon icon={faUserGraduate} />
                  </div>
                  <h3 className="odometer ">
                    <CountUp end={alumni_count} start={0} duration={25} />
                  </h3>

                  <span className="counter-one__letter ">+</span>
                  <p className="counter-one__text  ">
                    {" "}
                    <b>Alumni</b>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft "
              data-wow-delay="100ms"
              key="student"

            >
              <div className="counter-one__single  ">
                <div className="counter-one__content  background_blue">
                  <div className="counter_icon ">
                    <FontAwesomeIcon icon={faBrain} className="text_white" />
                  </div>
                  <h3 className="odometer ">
                    <CountUp end={student_count} start={0} duration={25} />
                  </h3>
                  <span className="counter-one__letter ">+</span>
                  <p className="counter-one__text ">
                    <b>Students</b>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft"
              data-wow-delay="100ms"
              key="jobs"

            >
              <div className="counter-one__single ">
                <div className="counter-one__content ">
                  <div className="counter_icon text-white">
                    <FontAwesomeIcon icon={faBriefcase} />
                  </div>
                  <h3 className="odometer ">
                    <CountUp end={jobs_count} start={0} duration={25} />
                  </h3>

                  <span className="counter-one__letter ">+</span>
                  <p className="counter-one__text  ">
                    {" "}
                    <b>Jobs</b>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft "
              data-wow-delay="100ms"
              key="events"

            >
              <div className="counter-one__single  ">
                <div className="counter-one__content  background_blue">
                  <div className="counter_icon ">
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      className="text_white"
                    />
                  </div>
                  <h3 className="odometer ">
                    <CountUp end={events_count} start={0} duration={25} />
                  </h3>
                  <span className="counter-one__letter ">+</span>
                  <p className="counter-one__text ">
                    <b>Events</b>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CounterOne;
