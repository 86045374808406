import {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  CLEAR_ERRORS,
} from "../constants/settingConstant";

const initialState = {
  settings: null,
  error: null,
  loading: false,
};

export const settingsReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        settings: action?.payload,
      };

    case GET_SETTINGS_FAIL:
      return { ...state, loading: false, error: action?.payload };

    case CLEAR_ERRORS:
      return { ...state, error: null, message: null };

    default:
      return state;
  }
};
